import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';

export default function PrivacyPolicy() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <section class="p-l-r banner-section inner-banner">
                <div class="inner-banner-content">
                    <h1 class="heading1 text_gradient">
                        Privacy Policy
                    </h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link href="/">Home</Link></li>
                            <li class="star"><i class="fa-solid fa-star-of-life"></i></li>
                            <li class="breadcrumb-item active">Privacy Policy</li>
                        </ol>
                    </nav>
                </div>
            </section>
            <section class="privacy-policy-section top bottom p-l-r-2">
                <svg class="separator separator1 separator--up" width="100%" height="100%" viewBox="0 0 100 10"
                    preserveAspectRatio="none">
                    <path class="separator__path path-anim" data-path-to="M 0 0 C 35 21 70 0 100 0 L 0 0 Z"
                        vector-effect="non-scaling-stroke" d="M 0 0 C 37 0 70 0 100 0 L 0 0 Z" />
                </svg>
                <div class="container-fluid">
                    <div class="row ">


                        <h2>1. Introduction Privacy Policy</h2>

                        <p>1.1. We are committed to safeguarding the privacy of our App and Services users.</p>

                        <p>1.2. This policy applies to all The Beyond Lifestyle Application, as defined in the Terms of Service, and services, including our applications, websites, features, and other services (collectively, the "App" and/or the "Services") where we are acting as a data controller with respect to the personal data of our App and Services users; in other words, where we determine the purposes and means of the processing of that personal data.</p>

                        <p>1.3. We use cookies on our website and application. Insofar as those cookies are not strictly necessary for the provision of our App and Services, we will ask you to consent to our use of cookies when you first visit our App.</p>

                        <p>1.4. In this policy, "we", "us" and "our" refer to The Beyond Lifestyle.</p>

                        <p>1.5. This Policy is incorporated as part of The Beyond Lifestyle Terms of Service and your use of the App or of the Services indicates your consent to them.</p>

                        <h2>2. How we use your personal data</h2>

                        <p>2.1. In Section 2 we have set out:</p>

                        <p>2.1.1. the general categories of personal data that we may process;</p>

                        <p>2.1.2. in the case of personal data that we did not obtain directly from you, the source and specific categories of that data;</p>

                        <p>2.1.3. the purposes for which we may process personal data; and</p>

                        <p>2.1.4. the legal basis of the processing.</p>

                        <p>2.2. We may process data about your use of our App and Services ("usage data"). The usage data may include your IP address, geographical location, browser type and version, operating system, length of visit, page views, and App navigation paths, as well as information about the timing, frequency, and pattern of your service use. This usage data may be processed for the purposes of analyzing the use of the App and services. The legal basis for this processing is consent or our legitimate interests, namely monitoring and improving our App and services.</p>

                        <p>2.3. We may process your account data ("account data"). The account data may include your name and email address. The account data may be processed for the purposes of operating our App, providing our services, ensuring the security of our App and services, maintaining back-ups of our databases, and communicating with you. The legal basis for this processing is consent or our legitimate interests, namely the proper administration of our App and business.</p>

                        <p>2.4. We may process the information included in your personal profile on our App ("profile data"). The profile data may include your name, address, telephone number, email address, profile photo/pictures, gender, date of birth, height and other related information to your wellness. The profile data may be processed for the purposes of enabling and monitoring your use of our App and services. The legal basis for this processing is consent or our legitimate interests, namely the proper administration of our App and business.</p>

                        <p>2.5. We may process your personal data that are provided in the course of the use of our services ("service data"). The service data may include your location data, your usage data, your designated training area, your use of any The Beyond Lifestyle to acquire any Incentives, and any information you provide while participating in a contest or draw. The service data may be processed for the purposes of operating our App, providing our Services, ensuring the security of our App and Services, maintaining back-ups of our databases and communicating with you. The legal basis for this processing is consent or our legitimate interests, namely the proper administration of our App and business.</p>

                        <p>2.6. We may process information that you post for publication on our App or through our Services ("publication data"). The publication data may be processed for the purposes of enabling such publication and administering our App and Services. The legal basis for this processing is consent or our legitimate interests, namely the proper administration of our App and business.</p>

                        <p>2.7. We may process information contained in any enquiry you submit to us regarding subscriptions, goods and/or services ("enquiry data"). The enquiry data may be processed for the purposes of offering, marketing and selling relevant subscriptions, goods and/or services to you. The legal basis for this processing is consent.</p>

                        <p>2.8. We may process information relating to our customer relationships, including customer contact information ("customer relationship data"). The customer relationship data may include your name, your contact details, and information contained in communications between us and you. The customer relationship data may be processed for the purposes of managing our relationships with customers, communicating with customers, keeping records of those communications and promoting our products and services to customers. The legal basis for this processing is consent or our legitimate interests, namely the proper management of our customer relationships.</p>

                        <p>2.9. We may process information relating to transactions, including purchases of subscriptions, goods and services, that you enter into with us and/or through our App ("transaction data"). The transaction data may include your contact details, any purchase made with The Beyond Lifestyle on our App, your card details and the transaction details. The transaction data may be processed for the purpose of supplying the purchased subscriptions, goods and services and keeping proper records of those transactions. The legal basis for this processing is the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract and our legitimate interests, namely the proper administration of our App and business.</p>

                        <p>2.10. We may process information that you provide to us for the purpose of subscribing to our email and App notifications and/or newsletters ("notification data"). The notification data may be processed for the purposes of sending you the relevant notifications, push notifications, emails, information and/or newsletters pertaining to the Services. The legal basis for this processing is consent or the performance of a contract between you and us.</p>

                        <p>2.11. We may process information contained in or relating to any communication that you send to us ("correspondence data"). The correspondence data may include the communication content and metadata associated with the communication. The correspondence data may be processed for the purposes of communicating with you and record keeping. The legal basis for this processing is our legitimate interests, namely the proper administration of our App and business and communications
                            with users.</p>

                        <p>2.12. We may process any of your personal data identified in this policy where necessary for the establishment, exercise or defense of legal claims, whether in court proceedings or in an administrative or out-of-court procedure. The legal basis for this process is our legitimate interests, namely the protection and assertion of our legal rights, your legal rights and the legal rights of others.</p>

                        <p>2.13. We may process any of your personal data identified in this policy where necessary for the purposes of obtaining or maintaining insurance coverage, managing risks, or obtaining professional advice. The legal basis for this processing is our legitimate interests, namely the proper protection of our business against risks.</p>
                        <p>2.14. In addition to the specific purposes for which we may process your personal data set out in this Section 2, we may also process any of your personal data where such processing is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person.</p>

                        <p>2.15. Please do not supply us with any other person's personal data, unless we prompt you to do so.</p>

                        <h2>3. Providing your personal data to others</h2>

                        <p>3.1. We may disclose your personal data to any member of our group of companies (this means our subsidiaries, our ultimate holding company and all its subsidiaries) insofar as reasonably necessary for the purposes, and on the legal basis, set out in this policy.</p>
                        <p>

                            3.2. We may disclose your personal data to our insurers and/or professional advisers insofar as reasonably necessary for the purposes of obtaining or maintaining insurance coverage, managing risks, obtaining professional advice, or the establishment, exercise or defense of legal claims, whether in court proceedings or in an administrative or out-of-court procedure.
                        </p>
                        <p>
                            3.3. We may disclose your account data, your profile data, your service data, your publication data, your enquiry data, your correspondence data, your transaction data, and your customer relationship data to our suppliers or subcontractors insofar as reasonably necessary for the provision of the App and Services.
                        </p>
                        <p>
                            3.4. Financial transactions relating to our App and Services are handled by our payment service providers Stripe and Apple Pay. We will share transaction data with our payment services providers only to the extent necessary for the purposes of processing your payments, refunding such payments and dealing with complaints and queries relating to such payments and refunds. You can find information about the payment services providers' privacy policies and practices at:

                            https://www.apple.com/legal/privacy/
                        </p>
                        <p>
                            3.5. Financial transactions, customer service relating to our App and Services, third party suppliers of goods and services identified on our App, and certain functionalities of our App are handled by our third party providers: Google Authentication, Facebook Authentication, Google Admob, Google Firebase, AWS, Google Cloud, mongodb, and Google. The Beyond Lifestyle will decide which one of the payment service providers or customer relationship management providers the user should use. We will share account data, profile data, service data, correspondence data, customer relationship data, and transaction data with our payment services providers and customer relationship management providers only to the extent necessary for the purposes of processing your payments, refunding such payments, dealing with complaints and queries relating to such payments and refunds, and providing customer service to you. You can find information about the payment services providers' and customer relationship management providers' privacy policies and practices at:</p>
                        <p className='link_paragraph'>
                            https://policies.google.com/privacy/
                        </p>
                        <p className='link_paragraph'>
                            https://www.facebook.com/privacy/policy https://aws.amazon.com/privacy/
                        </p>
                        <p className='link_paragraph'>
                            https://www.mongodb.com/legal/privacy/privacy-policy
                        </p>
                        <p className='link_paragraph'>
                            https://policies.google.com/privacy
                        </p>

                        <p>
                            3.6. We may disclose your enquiry data to one or more of those selected third party suppliers of goods and services identified on our App for the purpose of enabling them to contact you so that they can offer, and sell to you relevant goods and/or services. You can find information about third party service providers' privacy policies and practices at:
                        </p>
                        <p className='link_paragraph'>
                            https://policies.google.com/privacy/
                        </p>
                        <p className='link_paragraph'>
                            https://www.facebook.com/privacy/policy https://aws.amazon.com/privacy/
                        </p>
                        <p className='link_paragraph'>
                            https://www.mongodb.com/legal/privacy/privacy-policy
                        </p>
                        <p className='link_paragraph'>
                            https://policies.google.com/privacy
                        </p>

                        <p>

                            Each such third party will act as a data controller in relation to the enquiry data that we supply to it; and each such third party has its own privacy policy, which will govern that third party's use of your personal data. We advise you to read carefully those third parties' privacy policies.
                        </p>
                        <p>
                            3.7. In addition to the specific disclosures of personal data set out in this Section, we may disclose your personal data where such disclosure is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person. We may also disclose your personal data where such disclosure is necessary for the establishment, exercise or defense of legal claims, whether in court proceedings or in an administrative or out-of- court procedure.

                        </p>

                        <h2>4. Retaining and deleting personal data</h2>
                        <p>
                            4.1. This Section sets out our data retention policies and procedures, which are designed to help ensure that we comply with our legal obligations in relation to the retention and deletion of personal data.
                        </p>
                        <p>
                            4.2. Personal data that we process for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes, including providing you our App and Services.
                        </p>
                        <p>
                            4.3. The Beyond Lifestyle provides ways for you to access and delete your personal data as well as exercise other data rights which gives you a certain control over your personal information.
                        </p>
                        <p>
                            4.3.1. Email Subscriptions. You can always unsubscribe from our commercial or promotional emails by clicking unsubscribe in those messages. We will still send you transactional and relational emails about your use of the App and Services.
                        </p>
                        <p>
                            4.3.2. Push Notifications. You can opt out of receiving push notifications through your app settings. Please note that opting out of receiving push notifications may impact your use of the App and Services.</p>
                        <p>

                            4.3.3. Profile Information. You can review and edit certain account information you have chosen to add to your profile by logging in to your account settings and profile.</p>
                        <p>

                            4.3.4. Cookie Tracking. You can modify your cookie settings on your browser and, from time to time, if enabled, on your mobile device, but if you delete or choose not to accept our cookies, you may be missing out on certain features of the App.</p>
                        <p>

                            4.3.5. Do Not Track. Your browser or mobile device may offer you a "Do Not Track" option, which allows you to signal to operators of websites and applications and third-party services that you do not want them to track. </p>
                        <p>

                            4.3.6. Deleting Your Account. If you would like to delete your account, the same can be done by visiting the app setting in Menu. The user can also contact connect@tblsinfo.com. In some cases, we will be unable to delete your account, such as if there is an issue with your account related to trust, safety, or fraud. When we delete your account, we may retain certain information for legitimate business purposes or to comply with legal or regulatory obligations. When we retain such data, we do so in ways designed to prevent its use for other purposes.
                        </p>
                        <p>

                            Notwithstanding the other provisions of this Section, we may retain your personal data where such retention is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person.
                        </p>
                        <h2>5. Amendments</h2>
                        <p>
                            5.1. We may update this policy from time to time by publishing a new version on our App.</p>
                        <p>

                            5.2. You should check this page occasionally to ensure you are happy with any changes to this policy.</p>
                        <p>

                            5.3. We may notify you of significant changes to this policy by email or through notification on our App. </p>


                        <h2>6. Your rights</h2>
                        <p>
                            6.1. In this Section, we have summarized the rights that you have under data protection law. Some of the rights are complex, and not all of the details have been included in our summaries. Accordingly, you should read the relevant laws and guidance from the regulatory authorities for a full explanation of these rights.</p>
                        <p>

                            6.2. You have choices regarding our use and disclosure of your personal data:</p>
                        <p>

                            6.2.1. Opting out of receiving electronic communications from us. If you no longer want to receive marketing-related emails from us, you may opt-out via the unsubscribe link included in such emails. We will try to comply with your request(s) as soon as reasonably practicable. Please note that if you opt-out of receiving marketing-related emails from us, we may still send you important administrative messages that are required to provide you with our Services.</p>
                        <p>

                            6.2.2. To see or change your account personal data. If you would like to review, correct, or update personal data that you have previously disclosed to us, you may do so by signing into your account or by contacting us.
                        </p>
                        <p>
                            6.2.3. Your data protection rights. Depending on your location and subject to applicable law, you may have the following rights with regard to your personal data:
                        </p>
                        <p>
                            6.2.3.1. The right to request confirmation of whether The Beyond Lifestyle processes personal data relating to you, and if so, to request a copy of that personal data;
                        </p>
                        <p>
                            6.2.3.2. The right to request that The Beyond Lifestyle rectifies or updates your personal data that is inaccurate, incomplete or outdated
                        </p>
                        <p>
                            6.2.3.3. The right to request that The Beyond Lifestyle erase your personal data in certain circumstances provided by law;
                        </p>
                        <p>
                            6.2.3.4. The right to request that The Beyond Lifestyle restricts the use of your personal data in certain circumstances, such as while The Beyond Lifestyle considers another request that you have submitted (including a request that The Beyond Lifestyle make an update to your personal data); and
                        </p>
                        <p>
                            6.2.3.5. The right to request that we export to another company, where technically feasible, your personal data that we hold in order to provide the App and Services to you.
                        </p>
                        <p>
                            6.2.4. Consent. Where the processing of your personal data is based on your previously given consent, you have the right to withdraw your consent at any time. You may also have the right to object to the processing of your personal data on grounds relating to your particular situation.
                        </p>
                        <p>
                            6.2.5. Process for exercising data protection rights. In order to exercise your data protection rights, you may contact The Beyond Lifestyle as described in the Our Details section below. We take each request seriously. We will comply with your request to the extent required by applicable law. We will not be able to respond to a request if we no longer hold your personal data. If you feel that you have not received a satisfactory response from us, you may consult with the data protection authority in your country. If you consider that our processing of your personal information infringes data protection laws, you have a legal right to lodge a complaint with a supervisory authority responsible for data protection.
                        </p>
                        <p>
                            6.2.6. For your protection, we may need to verify your identity before responding to your request, such as verifying that the email address from which you send the request matches your email address that we have on file. If we no longer need to process personal data about you in order to provide our App and Services, we will not maintain, acquire or process additional information in order to identify you for the purpose of responding to your request.
                        </p>

                        <h2>7. About cookies</h2>
                        <p>
                            7.1. A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a server to a browser or your device and is stored by the browser or your device. The identifier is then sent back to the server each time the browser or device requests a page from the server.
                        </p>
                        <p>
                            7.2. Cookies may be either "persistent" cookies or "session" cookies: a persistent cookie will be stored by a web browser or device and will remain valid until its set expiry date, unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed.
                        </p>
                        <p>
                            7.3. Cookies do not typically contain any information that personally identifies a user, but personal information that we store about you may be linked to the information stored in and obtained from cookies.
                        </p>

                        <h2>8. Cookies that we use</h2>
                        <p>
                            8.1. We use cookies for the following purposes:
                        </p>
                        <p>
                            8.1.1. authentication—we use cookies to identify you when you visit our website and as you navigate our website or App;
                        </p>
                        <p>
                            8.1.2. status we use cookies to help us to determine if you are logged into our website or App;
                        </p>
                        <p>
                            8.1.3. shopping cart we use cookies to maintain the state of your shopping cart as you navigate our website or App;
                        </p>
                        <p>
                            8.1.4. personalization—we use cookies to store information about your preferences and to personalize our website or App for you;
                        </p>
                        <p>
                            8.1.5. security we use cookies as an element of the security measures used to protect user accounts, including preventing fraudulent use of login credentials, and to protect our website and services generally;
                        </p>
                        <p>
                            8.1.6. analysis/tracking-we use cookies to help us to recognise and count the number of visitors and analyse use of the services, as well as to verify transactions;
                        </p>
                        <p>
                            8.1.7. advertising—we use cookies to help us to display advertisements that will be relevant to you; and
                        </p>
                        <p>
                            8.1.8. cookie consent we use cookies to store your preferences in relation to the use of cookies more generally.
                        </p>

                        <h2>9. Cookies used by our service providers</h2>
                        <p>
                            9.1. Our service providers use cookies and those cookies may be stored on your computer when you visit our website or our App.
                        </p>
                        <p>
                            9.2. We use Google Analytics to analyze the use of our website. Google Analytics gathers information about website use by means of cookies. The information gathered relating to our website is used to create reports about the use of our website. Google's privacy policy is available at: <p className='link_paragraph'>https://www.google.com/policies/privacy/.</p>
                        </p>
                        <p>
                            9.3. The relevant cookies are: rc::c (used to distinguish humans from bots) and collect (used to send data to Google Analytics about the visitor's device and behavior. Tracks the visitor across devices and marketing channels).
                        </p>


                        <h2>10. Managing cookies</h2>
                        <p>
                            10.1. Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary from browser to browser, and from version to version. You can however obtain up-to-date information about blocking and deleting cookies via these links:
                        </p>
                        <p className='link_paragraph'>
                            10.1.1. https://support.google.com/chrome/answer/95647?hl=en (Chrome);
                        </p>
                        <p className='link_paragraph'>
                            10.1.2. https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-
                            preferences (Firefox);
                        </p>
                        <p className='link_paragraph'>
                            10.1.3. http://www.opera.com/help/tutorials/security/cookies/ (Opera);
                        </p>
                        <p className='link_paragraph'>
                            10.1.4. https://support.apple.com/kb/PH21411 (Safari); and
                        </p>
                        <p className='link_paragraph'>
                            10.1.5. https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy (Edge)
                        </p>
                        <p>
                            10.2. Blocking all cookies will have a negative impact upon the usability of many websites.
                        </p>
                        <p>
                            10.3. If you block cookies, you will not be able to use all the features on our website and/or our Services.
                        </p>
                        <h2>11. Our details</h2>
                        <p>
                            11.1. This website is owned and operated by Digifish Media Private Limited. based in Gururgam, India.
                        </p>
                        <p>
                            11.2. We are registered in Gurugram, Haryana, India, and our registered office is at 032, 4th Floor, Emerald Plaza, Sector-65, Gurugram, Haryana-122018. India.
                        </p>
                        <p>
                            11.3. You can contact us:
                        </p>
                        <p>
                            by post, to the postal address given above.
                            •   use our website contact form - https://thebeyondlifestyle.com/contact
                            •   email, using connect@tblsinfo.com
                        </p>
                        <p>
                            11.4. Privacy Policy & Terms of use
                            <p className='link_paragraph'>
                                •   check our policy on website:  https://thebeyondlifestyle.com/privacy-policy
                            </p>
                            <p className='link_paragraph'>
                                •   check our Terms of use on website:  https://thebeyondlifestyle.com/terms-and-conditions
                            </p>

                        </p>

                    </div>
                </div>
            </section >
        </>
    )
}
