import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
export default function TermsAndConditions() {


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <section class="p-l-r banner-section inner-banner">
                <div class="inner-banner-content">
                    <h1 class="heading1 text_gradient">
                        Terms and Conditions
                    </h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link href="/">Home</Link></li>
                            <li class="star"><i class="fa-solid fa-star-of-life"></i></li>
                            <li class="breadcrumb-item active">Terms and Conditions</li>
                        </ol>
                    </nav>
                </div>
            </section>
            <section class="privacy-policy-section top bottom p-l-r-2">
                <div class="container-fluid">
                    <svg class="separator separator1 separator--up" width="100%" height="100%" viewBox="0 0 100 10"
                        preserveAspectRatio="none">
                        <path class="separator__path path-anim" data-path-to="M 0 0 C 35 21 70 0 100 0 L 0 0 Z"
                            vector-effect="non-scaling-stroke" d="M 0 0 C 37 0 70 0 100 0 L 0 0 Z" />
                    </svg>
                    <div class="row">
                        <h3>LICENSED APPLICATION END USER LICENSE AGREEMENT</h3>

                        <p>
                            Apps made available through the App Store are licensed, not sold, to you. Your license to each App is subject to your prior acceptance of either this Licensed Application End User License Agreement (“Standard EULA”), or a
                            custom end user license agreement between you and the Application Provider (“Custom EULA”), if one is provided. Your license to any Apple App under this Standard EULA or Custom EULA is granted by Apple, and your license to
                            any Third Party App under this Standard EULA or Custom EULA is granted by the Application Provider of that Third Party App. Any App that is subject to this Standard EULA is referred to herein as the “Licensed Application.”
                            The Application Provider or Apple as applicable (“Licensor”) reserves all rights in and to the Licensed Application not expressly granted to you under this Standard EULA.
                        </p>
                        <p>
                            a. Scope of License: Licensor grants to you a non transferable license to use the Licensed Application on any Apple-branded products that you own or control and as permitted by the Usage Rules. The terms of this Standard
                            EULA will govern any content, materials, or services accessible from or purchased within the Licensed Application as well as upgrades provided by Licensor that replace or supplement the original Licensed Application, unless
                            such upgrade is accompanied by a Custom EULA. Except as provided in the Usage Rules, you may not distribute or make the Licensed Application available over a network where it could be used by multiple devices at the same
                            time. You may not transfer, redistribute or sublicense the Licensed Application and, if you sell your Apple Device to a third party, you must remove the Licensed Application from the Apple Device before doing so. You may not
                            copy (except as permitted by this license and the Usage Rules), reverse-engineer, disassemble, attempt to derive the source code of, modify, or create derivative works of the Licensed Application, any updates, or any part
                            thereof (except as and only to the extent that any foregoing restriction is prohibited by applicable law or to the extent as may be permitted by the licensing terms governing use of any open-sourced components included with
                            the Licensed Application).
                        </p>
                        <p>
                            b. Consent to Use of Data: You agree that Licensor may collect and use technical data and related information—including but not limited to technical information about your device, system and application software, and
                            peripherals—that is gathered periodically to facilitate the provision of software updates, product support, and other services to you (if any) related to the Licensed Application. Licensor may use this information, as long
                            as it is in a form that does not personally identify you, to improve its products or to provide services or technologies to you.
                        </p>
                        <p>
                            c. Termination. This Standard EULA is effective until terminated by you or Licensor. Your rights under this Standard EULA will terminate automatically if you fail to comply with any of its terms.
                        </p>
                        <p>
                            d. External Services. The Licensed Application may enable access to Licensor’s and/or third-party services and websites (collectively and individually, "External Services"). You agree to use the External Services at your
                            sole risk. Licensor is not responsible for examining or evaluating the content or accuracy of any third-party External Services, and shall not be liable for any such third-party External Services. Data displayed by any
                            Licensed Application or External Service, including but not limited to financial, medical and location information, is for general informational purposes only and is not guaranteed by Licensor or its agents. You will not use
                            the External Services in any manner that is inconsistent with the terms of this Standard EULA or that infringes the intellectual property rights of Licensor or any third party. You agree not to use the External Services to
                            harass, abuse, stalk, threaten or defame any person or entity, and that Licensor is not responsible for any such use. External Services may not be available in all languages or in your Home Country, and may not be
                            appropriate or available for use in any particular location. To the extent you choose to use such External Services, you are solely responsible for compliance with any applicable laws. Licensor reserves the right to change,
                            suspend, remove, disable or impose access restrictions or limits on any External Services at any time without notice or liability to you.
                        </p>
                        <p>
                            e. NO WARRANTY: YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE LICENSED APPLICATION IS AT YOUR SOLE RISK. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE LICENSED APPLICATION AND ANY SERVICES PERFORMED OR
                            PROVIDED BY THE LICENSED APPLICATION ARE PROVIDED "AS IS'' AND “AS AVAILABLE,” WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND, AND LICENSOR HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH RESPECT TO THE LICENSED
                            APPLICATION AND ANY SERVICES, EITHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES AND/OR CONDITIONS OF MERCHANTABILITY, OF SATISFACTORY QUALITY, OF FITNESS FOR A PARTICULAR PURPOSE,
                            OF ACCURACY, OF QUIET ENJOYMENT, AND OF NON INFRINGEMENT OF THIRD-PARTY RIGHTS. NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY LICENSOR OR ITS AUTHORIZED REPRESENTATIVE SHALL CREATE A WARRANTY. SHOULD THE LICENSED
                            APPLICATION OR SERVICES PROVE DEFECTIVE, YOU ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR, OR CORRECTION. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON APPLICABLE
                            STATUTORY RIGHTS OF A CONSUMER, SO THE ABOVE EXCLUSION AND LIMITATIONS MAY NOT APPLY TO YOU.
                        </p>
                        <p>
                            f. Limitation of Liability. TO THE EXTENT NOT PROHIBITED BY LAW, IN NO EVENT SHALL LICENSOR BE LIABLE FOR PERSONAL INJURY OR ANY INCIDENTAL, SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING, WITHOUT
                            LIMITATION, DAMAGES FOR LOSS OF PROFITS, LOSS OF DATA, BUSINESS INTERRUPTION, OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OF OR INABILITY TO USE THE LICENSED APPLICATION, HOWEVER CAUSED,
                            REGARDLESS OF THE THEORY OF LIABILITY (CONTRACT, TORT, OR OTHERWISE) AND EVEN IF LICENSOR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF LIABILITY FOR PERSONAL INJURY,
                            OR OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THIS LIMITATION MAY NOT APPLY TO YOU.
                        </p>
                        <p>
                            g. You may not use or otherwise export or re-export the Licensed Application except as authorized by Indian law and the laws of the jurisdiction in which the Licensed Application was obtained. In particular, but without
                            limitation, the Licensed Application may not be exported or re-exported <br />
                            (a) into any India-embargoed countries or <br />
                            (b) to anyone on the India Treasury Department's Specially Designated Nationals List or the India Department of Commerce Denied Persons List or Entity List. By using the Licensed Application, you represent and warrant that
                            you are not located in any such country or on any such list. You also agree that you will not use these products for any purposes prohibited by Indian law, including, without limitation, the development, design, manufacture,
                            or production of nuclear, missile, or chemical or biological weapons.
                        </p>
                        <p>
                            h. The Licensed Application and related documentation are "Commercial Items", as that term is defined at https://www.meity.gov.in/. The Commercial Computer Software and Commercial Computer Software Documentation are being
                            licensed to India Government end users <br />
                            (a) only as Commercial Items and <br />
                            (b) with only those rights as are granted to all other end users pursuant to the terms and conditions herein. Unpublished-rights reserved under the copyright laws of India.
                        </p>
                        <p>
                            i. Except to the extent expressly provided in the following paragraph, this Agreement and the relationship between you and Apple shall be governed by the laws of India, excluding its conflicts of law provisions. You and
                            Apple agree to submit to the personal and exclusive jurisdiction of the courts located within the country of India, to resolve any dispute or claim arising from this Agreement. If <br />
                            (a) you are not a Indian citizen; <br />
                            (b) you do not reside in the India.; <br />
                            (c) you are not accessing the Service from the India; and <br />
                            (d) you are a citizen of one of the countries identified below, you hereby agree that any dispute or claim arising from this Agreement shall be governed by the applicable law set forth below, without regard to any conflict
                            of law provisions, and you hereby irrevocably submit to the non-exclusive jurisdiction of the courts located in the state, province or country identified below whose law governs: <br />
                            Specifically excluded from application to this Agreement is that law known as the United Nations Convention on the International Sale of Goods.
                        </p>

                        <h4>ACCEPTANCE BLURB:</h4>
                        <p>
                            IMPORTANT! YOUR USE OF THE APP OR PURCHASE OF ANY SERVICES IS SUBJECT TO LEGALLY BINDING TERMS AND CONDITIONS. CAREFULLY READ ALL OF THE APPLICABLE TERMS AND CONDITIONS AS SET OUT BELOW. IF YOU ACCEPT THESE TERMS OF
                            SERVICES, CLICK THE “I ACCEPT” BUTTON ON THE BOX BELOW. THAT ACTION IS THE EQUIVALENT OF YOUR SIGNATURE AND INDICATES YOUR ACCEPTANCE OF THE TERMS OF SERVICES AND THAT YOU INTEND TO BE LEGALLY BOUND BY THEM. THAT ACTION
                            SHALL ALSO BE THE EQUIVALENT OF PROVIDING US WITH ALL NECESSARY CONSENTS AS OUTLINED IN THESE TERMS OF SERVICES. IF THERE IS AN ERROR IN THE TERMS AND CONDITIONS OR IF YOU DO NOT AGREE WITH THEM, PLEASE CLICK ON THE “BACK”
                            BUTTON OF YOUR BROWSER TO LEAVE.
                        </p>

                        <h4>TERMS OF USE</h4>
                        <p>
                            Last Updated: March 27, 2024
                        </p>
                        <p>
                            Thank you for using The Beyond Lifestyle! Please read these terms of service carefully. These terms contain important information about your legal rights, remedies and obligations. By downloading, installing, accessing or
                            using The Beyond Lifestyle App or Services, you agree to comply with and be bound by these terms and all applicable laws and regulations. <br />
                            These terms of use (these “Terms” or “Terms of Use”) constitute a legally binding agreement (the “Agreement”) between you and Digifish3 Media Private Limited (“The Beyond Lifestyle”, the “Company”, “we”, “us”, or “our”)
                            governing access to and use of the The Beyond Lifestyle website at https://thebeyondlifestyle.com/ including any subdomains thereof, and any other websites through which The Beyond Lifestyle makes its services available
                            (together, the “Website”) and application on Apple Store and Google Play, including any applicable web, mobile, tablet or other smart-device applications, and application program interfaces (collectively, the "Application")
                            and all associated services (collectively, the "Services"). The Website, Application and Services are collectively referred to as the “App”. <br />
                            Our collection and use of personal information in connection with your access to and use of the The Beyond Lifestyle App is described in our Privacy Policy. You must read, agree to, and accept all of the terms and conditions
                            contained in this Agreement to be a User of our Website located at https://thebeyondlifestyle.com/ or any part of the rest of the App, whether listed on Apple Store or Google Play.
                        </p>
                        <p>
                            This Agreement includes and hereby incorporates by reference the following important agreements, as they may be in effect and modified from time to time: our Privacy Policy. These agreements are collectively, with this
                            Agreement, called the “Terms of Service”.
                        </p>
                        <p>
                            Subject to the conditions set forth herein, The Beyond Lifestyle may, in its sole discretion, amend this Agreement and the other Terms of Service at any time by posting a revised version on the App. The Beyond Lifestyle will
                            provide reasonable advance notice of any amendment that includes a Substantial Change (defined below), by posting the updated Terms of Service on the App, providing notice on the App, and/or sending you notice by email when
                            applicable. <br />
                            If the Substantial Change includes an increase to Fees charged by The Beyond Lifestyle, The Beyond Lifestyle will provide at least 30 days’ advance notice of the change, but may not provide any advance notice for changes
                            resulting in a reduction in Fees or any temporary or promotional Fee change. <br />
                            Any revisions to the Terms of Service will take effect on the noted effective date (each, as applicable, the “Effective Date”).
                        </p>
                        <p>
                            YOU UNDERSTAND THAT BY USING THE APP OR APP SERVICES AFTER THE EFFECTIVE DATE, YOU AGREE TO BE BOUND BY THE TERMS OF SERVICE. IF YOU DO NOT ACCEPT THE TERMS OF SERVICE IN ITS ENTIRETY, YOU MUST NOT ACCESS OR USE THE APP OR
                            THE APP SERVICES AFTER THE EFFECTIVE DATE EXCEPT AS MAY BE PERMITTED HEREIN.
                        </p>
                        <h4>1. DEFINITIONS</h4>

                        <p>The following terms are used throughout these Terms and have the specific meaning provided below:</p>
                        <p>
                            “Account” means any account on the The Beyond Lifestyle App;
                        </p>
                        <p>
                            “Application” means, collectively, the The Beyond Lifestyle App together with any applicable mobile, tablet, or other smart device applications and application program interfaces and all associated services;
                        </p>
                        <p>
                            “App” or “The Beyond Lifestyle App” means the Application, Website, and any associated services provided by Finally;
                        </p>
                        <p>
                            “including” or “include(s)” as used herein means including, without limitation;
                        </p>
                        <p>
                            “Intellectual Property Rights” means all patent rights, copyright rights, mask work rights, moral rights, rights of publicity, trademark, trade dress and service mark rights, goodwill, trade secret rights and other
                            intellectual property rights as may now exist or hereafter come into existence, and all applications therefore and registrations, renewals and extensions thereof, under the laws of any state, province, country, territory or
                            other jurisdiction;
                        </p>
                        <p>
                            “Penalty” means the penalty described in Section 8.2 of these Terms of Service;
                        </p>
                        <p>
                            “User”, “you”, or “your” refers to the individual or entity that has visited the Website and/or the App, or downloaded and/or installed the App;
                        </p>
                        <p>
                            “User Content” means any comments, remarks, data, feedback, content, files, documents, text, photographs, images, video, music, or other information that you or any visitor or User post to any part of the App or provide to
                            the Company, including such information that is posted as a result of questions;
                        </p>
                        <h4>2. AGE</h4>

                        <p>
                            The App is not intended for use by children or people under the age of 18. If you are under the age of 18 you must not use the App. If we discover that any such use is taking place, we will immediately terminate any relevant
                            user account(s) facilitating such use.
                        </p>

                        <p>
                            Age Restriction and Parental Consent:
                        </p>
                        <p>
                            2.1 This App is not intended for, and access to the App is not permitted to, users under the age of eighteen (18).
                        </p>
                        <p>
                            2.2 If you are under the age of eighteen (18), you may only access and use the App with the verifiable permission and consent of a parent or legal guardian who agrees to be bound by the terms of this Agreement.
                        </p>

                        <h4>3. The Beyond Lifestyle ACCOUNTS</h4>
                        <p>
                            3.1. Registration and Acceptance
                        </p>
                        <p>
                            A User may need to open an Account with us to access the App and our Services, from time to time and as specified on the App. When you register for an Account to use the App, or by clicking to accept the Terms of Service
                            when prompted on the App, you agree to abide by this Agreement and the other Terms of Service, as applicable.
                        </p>
                        <p>
                            To access and use certain portions of the App and the App Services, you may need to register for an Account. When applicable, subject to these Terms of Use, certain portions of the App are available to App users, including
                            those portions before your Account registration is accepted. We reserve the right to decline a registration to join The Beyond Lifestyle, for any lawful reason, including supply and demand, cost to maintain data, or other
                            business considerations. We expressly disclaim any obligation to provide you with reasons if we decline to register your Account.
                        </p>
                        <p>
                            3.2. Account Eligibility The Beyond Lifestyle offers the App and Services as a means for consumers to listen to podcasts, sounds & tunes, engage with communities, practice meditation & breathing exercise, journal their
                            thoughts, and read articles, blogs or content related to wellness etc. When required, creating an Account to use our App is subject to the following eligibility restrictions. To register either for an Account or use the App
                            and App Services as a User, you must, and hereby represent that you: <br />
                            (a) are an individual; <br />
                            (b) will use the App and Services for your benefit; and <br />
                            (c) are an individual 18 years or older (or have otherwise reached the age of majority in your jurisdiction of residence) and can form legally binding contracts. 3.3. Account Permissions
                        </p>
                        <p>
                            You agree not to request or allow another person to create an Account on your behalf, for your use, or for your benefit. 3.4. Identity and Location Verification
                        </p>
                        <p>
                            When you register for an Account and from time to time thereafter, your Account may be subject to verification, which may include, but shall not be limited to, validation against third-party databases or the verification of
                            any necessary documents that confirm your identity and your location on The Beyond Lifestyle. The requirements for identification and location verification contained in our Terms of Service are to foster an environment of
                            trust and protect the safety, privacy and security of all Users. 3.5. Usernames and Passwords
                        </p>
                        <p>
                            When you register for an Account, you will be asked to choose a username and password for the Account. You are entirely responsible for safeguarding and maintaining the confidentiality of your username and password and agree
                            not to share your username or password with any person who is not authorized to use your Account. We will assume that any person using the App with your username and password, either is you or is deemed to be authorized to
                            act for you. You agree to notify us immediately if you suspect or become aware of any unauthorized use of your Account or any unauthorized access to the password for any Account. You further agree not to use the Account or
                            log in with the username and password of another User of the App if
                        </p>
                        <p>
                            (a) you are not authorized to use either or <br />
                            (b) the use would violate the Terms of Service.
                        </p>
                        <p>
                            To the maximum extent permitted by applicable law, The Beyond Lifestyle shall not be liable to you for any unauthorised use of your Account resulting from your failure to maintain restricted access to your mobile device or
                            your mobile SIM card, the confidentiality or security of your user details or failure to use a strong password. Access to your account can become restricted or outright impossible in case of a loss of your control over the
                            mobile number to which your Account is registered.
                        </p>

                        <h4>4. RELATIONSHIPS ON THE The Beyond Lifestyle APP</h4>

                        <p>4.1. Services on the App</p>
                        <p>
                            The App tracks how you interact with the app (date & time), the sessions you view including the preferences and settings you choose. The app tracks the engagement of content. We collect information about how you interact
                            with posts, comments, and forum contributions. We may collect data about your device like type, operating system, and any other unique identifiers like device ID, etc. We only access your location with your consent.
                        </p>
                        <p>
                            The User shall provide all necessary information as prompted on the App, which shall constitute part of the User Content. This information may include the User’s personal information, such as full name, full address, mobile
                            telephone number, and email, User’s preference on language or any other information which may be prompted on the App.
                        </p>
                        <p>
                            Your Account logs details of all The Beyond Lifestyle generated by you and any transfers of The Beyond Lifestyle to or from your Account. Please review your Account regularly. If you identify any erroneous transaction or
                            unexpected activity on your Account, or you believe that the security of your Account has been compromised, you must let us know as soon as possible.
                        </p>
                        <p>
                            Different membership levels may apply to the Account of different users and, depending on the membership level the User holds, the User’s entitlement to access functionality of the App or generate The Beyond Lifestyle may
                            vary. You may find the different membership plans - different price slabs and different plan duration by clicking the GetPlus tab in the menu section.
                        </p>
                        <p>
                            When using the App on your mobile device, you acknowledge and agree that the App operates in the background of your mobile device, which in turn may have an impact on the battery life of such device.
                        </p>

                        <h4>5. WARRANTY, LIABILITY AND RELEASE</h4>
                        <p>
                            5.1. Warranty Disclaimer
                        </p>
                        <p>
                            You agree not to rely on the App, the Services, any information on the site or the continuation of the App. The App and the Services are provided on and “as is” and an “as available” basis. The Beyond Lifestyle makes no
                            representations or warranties with regard to the App, the Services, your wellness goals, any result you wish to achieve while using the App or any services related to this Agreement or the Terms of Service. To the maximum
                            extent permitted by applicable law, the Company disclaims all express and implied conditions, representations, and warranties including, but not limited to, the warranties of merchantability, accuracy, fitness for a
                            particular purpose, title, and non-infringement. Some jurisdictions may not allow for all of the foregoing limitations on warranties, so to that extent, some or all of the above limitations may not apply to you.
                        </p>
                        <p>
                            The App allows you to track your history.
                        </p>
                        <p>
                            THE SERVICES WE PROVIDE DO NOT CONTAIN OR CONSTITUTE, AND SHOULD NOT BE INTERPRETED AS, ANY FORM OF MEDICAL & PSYCHOLOGICAL ADVICE OR OPINION. We are not licensed medical professionals & mental wellness experts, and we are
                            not in the business of providing medical & personal advice. You should always consult a qualified and licensed medical & mental wellness professional prior to beginning or modifying any program or lifestyle.
                        </p>
                        <p>
                            The App is not a tool for the diagnosis or prevention of disease. We do not endorse any third party products or services listed on the App and, where these relate to your health, you should always take appropriate medical
                            advice before using them. YOUR USE OF THE APP DOES NOT CREATE A DOCTOR-PATIENT RELATIONSHIP BETWEEN YOU AND THE COMPANY.
                        </p>
                        <p>
                            Disclaimer: Medical and Psychological Information
                        </p>
                        <p>
                            • The Services provided by The Beyond Lifestyle app are for informational purposes only and are not intended to be a substitute for professional medical or psychological advice, diagnosis, or treatment
                        </p>
                        <p>
                            • You should not rely on any information provided through the Services to make any decisions about your health
                        </p>
                        <p>
                            • You are strongly encouraged to consult with a qualified healthcare professional regarding any medical or psychological conditions you may have
                        </p>
                        <p>
                            • We expressly disclaim any and all liability for any actions or omissions you take in reliance on any information provided through the Services
                        </p>
                        <p>
                            • By using the Services, you acknowledge and agree that you are solely responsible for your own health and well-being
                        </p>
                        <p>
                            • The Services are not intended for use by individuals with pre-existing medical conditions without consulting a medical professional
                        </p>
                        <p>
                            5.2. Limitation of Liability
                        </p>
                        <p>
                            The Beyond Lifestyle is not liable, and you agree not to hold us responsible, for any damages or losses arising out of or in connection with the Terms of Service, including, but not limited to:
                        </p>
                        <p>
                            1. your use of or your inability to use our App or Services;
                        </p>
                        <p>
                            2. delays or disruptions in our App or Services;
                        </p>
                        <p>
                            3. viruses or other malicious software obtained by accessing, or linking to, our App or Services;
                        </p>
                        <p>
                            4. glitches, bugs, errors, or inaccuracies of any kind in our App or Services;
                        </p>
                        <p>
                            5. damage to your hardware from the use of the App or Services;
                        </p>
                        <p>
                            6. the content, actions, or inactions of third parties’ use of the App or Services;
                        </p>
                        <p>
                            7. a suspension or other action taken with respect to your Account;
                        </p>
                        <p>
                            8. your need to modify practices, content, or behavior as a result of changes to the Terms of Service.
                        </p>
                        <p>
                            9. No use of any nudity based content is showcased in the application. If any objectionable content is found, you can contact us on connect@tblsinfo.com
                        </p>
                        <p>
                            ADDITIONALLY, IN NO EVENT WILL THE BEYOND LIFESTYLE , OUR AFFILIATES, OR OUR THIRD-PARTY SERVICE PROVIDERS, AS APPLICABLE, BE LIABLE FOR ANY SPECIAL, CONSEQUENTIAL, INCIDENTAL, PUNITIVE, EXEMPLARY, OR INDIRECT COSTS OR
                            DAMAGES, INCLUDING, BUT NOT LIMITED TO, LITIGATION COSTS, INSTALLATION AND REMOVAL COSTS, OR LOSS OF DATA, PRODUCTION, LACK OF INTENDED BEHAVIOURAL RESULT, CHANGES IN LIFESTYLE, CHANGES IN MENTAL WELLNESS, ANY PENALTY, ANY
                            REDEEMED GOODS, SERVICES OR OTHER BENEFITS, PROFIT, OR OPPORTUNITIES RELATED TO THE SERVICES. THESE LIMITATIONS WILL APPLY TO ANY LIABILITY, ARISING FROM ANY CAUSE OF ACTION WHATSOEVER ARISING OUT OF OR IN CONNECTION WITH
                            THIS AGREEMENT OR THE OTHER TERMS OF SERVICE, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR OTHERWISE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH COSTS OR DAMAGES AND EVEN IF THE LIMITED REMEDIES
                            PROVIDED HEREIN FAIL OF THEIR ESSENTIAL PURPOSE. SOME JURISDICTIONS MAY NOT ALLOW FOR ALL OF THE FOREGOING EXCLUSIONS AND LIMITATIONS, SO TO THAT EXTENT, SOME OR ALL OF THESE LIMITATIONS AND EXCLUSIONS MAY NOT APPLY TO YOU.
                        </p>

                        <p>5.3. Indemnification</p>

                        <p>
                            You will indemnify, defend, and hold harmless The Beyond Lifestyle, our affiliates, and our respective directors, officers, employees, representatives, partners, and agents (each an “Indemnified Party”) for all Indemnified
                            Claims (defined below) and Indemnified Liabilities (defined below) relating to or arising out of: <br />
                            (a) the use of the App and the Services by you or your agents, including any payment obligations or default incurred through use of the Services; <br />
                            (b) failure to comply with the Terms of Service by you or your agents; <br />
                            (c) failure to comply with applicable law by you or your agents; <br />
                            (d) negligence, willful misconduct, or fraud by you or your agents; and <br />
                            (e) defamation, libel, violation of privacy rights, unfair competition, or infringement of Intellectual Property Rights or allegations thereof to the extent caused by you or your agents. For purposes of this Section, your
                            agents include any person who has apparent authority to access or use your Account demonstrated by using your username and password. <br />
                            “Indemnified Claim” means any and all claims, damages, liabilities, costs, losses, and expenses (including attorneys’ fees and all related costs and expenses) arising from or relating to any claim, suit, proceeding, demand,
                            or action brought by you or a third party or other User against an Indemnified Party.
                        </p>
                        <p>
                            “Indemnified Liability” means any and all claims, damages, liabilities, costs, losses, and expenses (including attorneys’ fees and all related costs and expenses) arising from or relating to any claim, suit, proceeding,
                            demand, or action brought by an Indemnified Party against you or a third party or other User.
                        </p>
                        <h4>6. ACCESS TO OUR WEBSITE AND APP</h4>

                        <p>
                            You may have access to and use of the The Beyond Lifestyle App, or certain areas or features of the App, subject to certain conditions or requirements, such as completing a verification process, meeting specific quality or
                            eligibility criteria. You may need to register an account (an "Account") to access and use certain features of the The Beyond Lifestyle App.
                        </p>
                        <p>
                            6.1. Ownership
                        </p>
                        <p>
                            This App is owned and operated by Digifish3 Media Private Limited. All right, title and interest in and to the materials provided on our App, including but not limited to information, documents, logos, graphics, sounds and
                            images (the "Materials") are owned either by The Beyond Lifestyle or by our respective third-party authors, developers or vendors ("Third Party Providers"). Except as otherwise expressly provided by The Beyond Lifestyle,
                            none of the Materials may be copied, reproduced, republished, downloaded, uploaded, posted, displayed, transmitted or distributed in any way and nothing on this App or on any Applications shall be construed to confer any
                            license under any of our Intellectual Property Rights, whether by estoppel, implication or otherwise. We do not sell, license, lease or otherwise provide any of the Materials other than those specifically identified as being
                            provided by The Beyond Lifestyle. Any rights not expressly granted herein are reserved by the Company.
                        </p>
                        <p>
                            6.2. Limited App License
                        </p>
                        <p>
                            The Beyond Lifestyle hereby grants you a limited license to access the App and App Services. This license is subject to and conditioned on compliance with the App Terms of Use and, to the extent applicable, the rest of the
                            Terms of Service. We strive to keep our App and the App Services safe, secure, and functioning properly, but we cannot guarantee the continuous operation of or access to our App Services. In fact, we might even stop
                            providing certain features without notice. The Beyond Lifestyle shall grant you a non-exclusive, non-transferable, revocable licence to do the following in accordance with these Terms of Service (the “Licences”): <br />
                            (i) download, install and use the App, and any updates to it provided by The Beyond Lifestyle from time to time, in object code form for your personal use only on a compatible mobile device owned or controlled by you; and
                            <br />
                            (ii) access and browse the Website for your personal use only.
                        </p>
                        <p>
                            6.3. Termination of the Limited App License
                        </p>
                        <p>
                            The Beyond Lifestyle may terminate any license it has granted to any Website visitor or to access the App to anyone at its sole and unfettered discretion by providing notice, and the termination of such license shall be
                            effective immediately upon The Beyond Lifestyle providing such notice.
                        </p>
                        <p>
                            6.4. Permitted Uses
                        </p>
                        <p>
                            The Beyond Lifestyle offers the App and App Services for personal and consumer purposes. We make the App available for consumers to promote better mindful habits and to push them to reach their wellness goals. In addition,
                            certain Services, may be used to obtain, general information and articles that we believe may be of interest to App visitors and users. While we try to ensure that any information we post is both timely and accurate, errors
                            may appear from time to time. We do not make any representations or warranties with respect to any information that is posted on the App by us or anyone else. In no event should any content be relied on or construed as any
                            advice whatsoever or otherwise. You should independently verify the accuracy of any content.
                        </p>
                        <p>
                            6.5. Prohibited Uses
                        </p>
                        <p>
                            Users are not permitted to use, or encourage, promote, facilitate, instruct or induce others to use, the App for any activities that violate any law, statute, ordinance or regulation; for any other illegal or fraudulent
                            purpose or any purpose that is harmful to others; or to transmit, store, display, distribute or otherwise make available content that is illegal, fraudulent or harmful to others.
                        </p>
                        <p>
                            The following are examples of uses that are prohibited on the App or when using the Services or the App:
                        </p>
                        <p>
                            • seek to generate The Beyond Lifestyle by any means other than by the accepted eligibility criteria specified by The Beyond Lifestyle from time to time;
                        </p>
                        <p>
                            • copy, adapt, transmit, reverse engineer, decompile, disassemble, modify, sell the App or any content accessible on either, save as otherwise expressly permitted by these Terms of Service or applicable law;
                        </p>
                        <p>
                            • operate more than one Account;
                        </p>
                        <p>
                            • reproduce, frame, display or mirror the App other than as a necessary consequence of their normal operation by you;
                        </p>
                        <p>
                            • infringe our intellectual property rights or those of any third party in relation to your use of the App;
                        </p>
                        <p>
                            • employ any technology that interferes in any way with the proper operation of the App, including by blocking any advertising or promotions displayed thereon; or
                        </p>
                        <p>
                            • collect or harvest any information or data from the App for any commercial purpose, save as otherwise agreed in writing by us.
                        </p>
                        <p>
                            • use the App in any way or take any action that causes, or may cause, damage to the App or impairment of the performance,
                        </p>
                        <p>availability, accessibility, integrity or security of the App;</p>
                        <p>
                            • use our website in any way that is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity;
                        </p>
                        <p>
                            • hack or otherwise tamper with our App;
                        </p>
                        <p>
                            • probe, scan or test the vulnerability of our website without our permission;
                        </p>
                        <p>
                            • circumvent any authentication or security systems or processes on or relating to our App;
                        </p>
                        <p>
                            • use our App to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit or other malicious
                            computer software;
                        </p>
                        <p>
                            • decrypt or decipher any communications sent by or to the App without The Beyond Lifestyle’s permission;
                        </p>
                        <p>
                            • conduct any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction and data harvesting) on or in relation to the App without our express written consent;
                        </p>
                        <p>
                            • access or otherwise interact with the App using any robot, spider or other automated means; (q) do anything that interferes with the normal use of the App.
                        </p>
                        <p>
                            If we discover any breach of this Section by you, we may immediately suspend or terminate your access to the App and remove and delete any Materials or User Content from the App violating this Section.
                        </p>
                        <p>
                            6.6. Enforcement
                        </p>
                        <p>
                            The Beyond Lifestyle reserves the right, but does not assume the obligation, to investigate any potential violation of this Section or any other potential violation of the Terms of Service and to remove, disable access to,
                            or modify any content on the App. Our failure to act with respect to a breach by you or others does not waive our right to act with respect to subsequent or similar breaches. We do not guarantee we will take action against
                            all breaches of this Agreement or the Terms of Service.
                        </p>
                        <p>
                            6.7. Reporting and Collecting Violations
                        </p>
                        <p>
                            If you become aware of any violation of the Terms of Service, you must immediately report it to the Company. You agree to assist us with any investigation we undertake and to take any remedial steps we require in order to
                            correct a violation of the Terms of Service.
                        </p>
                        <p>
                            6.8. Third Parties
                        </p>
                        <p>
                            The Beyond Lifestyle may tell you about third party products or services. The Beyond Lifestyle may offer products and services on behalf of third parties who are not affiliated with The Beyond Lifestyle (“Third Party
                            Products”) and/or provide access or links to third party websites ("Third Party Sites"). <br />
                            If you decide to use any Third Party Products or access any Third Party Sites, you are solely responsible for your selection and review of separate product terms, website terms and privacy policies. The Beyond Lifestyle is
                            not affiliated with these Third Party Products or Third Party Sites and does not endorse or recommend Third Party Products even if such products are marketed or distributed via our Marketplace or associated with Finally in
                            any way. You agree that the third parties, and not The Beyond Lifestyle are solely responsible for the Third Party Products’ performance (including technical support), quality, and prices, the content on their websites and
                            their use or disclosure of your data. The Beyond Lifestyle will not be liable for any damages, claims or liabilities arising from the third parties, Third Party Products or Third Party Sites.
                        </p>

                        <h4>7. USER-GENERATED CONTENT</h4>

                        <p>
                            The Beyond Lifestyle may, at its sole discretion, enable Users to (i) create, upload, post, send, receive and store User Content, such as text, photos, audio, documents, files, video, or other materials and information on or
                            through the App; and (ii) access and view User Content and any content that The Beyond Lifestyle itself makes available on or through the App, including proprietary The Beyond Lifestyle content and any content licensed or
                            authorized for use by or through The Beyond Lifestyle from a third party ("Company Content" and together with User Content, "Collective Content").
                        </p>
                        <p>
                            7.1. Legal Protection and Ownership
                        </p>
                        <p>
                            The App, Company Content, and User Content may in its entirety or in part be protected by copyright, trademark, and/or other laws of India and other countries. You acknowledge and agree that the App and Company Content,
                            including all associated Intellectual Property Rights, are the exclusive property of The Beyond Lifestyle and/or its licensors or authorizing third parties. You will not remove, alter or obscure any copyright, trademark,
                            service mark or other proprietary rights notices incorporated in or accompanying the App, Company Content or User Content. All trademarks, service marks, logos, trade names, and any other source identifiers of The Beyond
                            Lifestyle used on or in connection with the App and Company Content are trademarks or registered trademarks of the Company in India and abroad. Trademarks, service marks, logos, trade names and any other proprietary
                            designations of third parties used on or in connection with the App, Company Content, and/or Collective Content are used for identification purposes only and may be the property of their respective owners.
                        </p>
                        <p>
                            7.2. Restrictions
                        </p>
                        <p>
                            You will not use, copy, adapt, modify, prepare derivative works of, distribute, license, sell, transfer, publicly display, publicly perform, transmit, broadcast or otherwise exploit the App or Collective Content, except to
                            the extent you are the legal owner of certain User Content or as expressly permitted in these Terms. No licenses or rights are granted to you by implication or otherwise under any intellectual property rights owned or
                            controlled by The Beyond Lifestyle or its licensors, except for the licenses and rights expressly granted in these Terms, if any.
                        </p>
                        <p>
                            7.3. Limited License
                        </p>
                        <p>
                            Subject to your compliance with these Terms, The Beyond Lifestyle grants you a limited, non-exclusive, non-sublicensable, revocable, non-transferable license to (i) download and use the Application on your personal
                            device(s); and (ii) access and view any Collective Content made available on or through the The Beyond Lifestyle App and accessible to you, solely for your personal and non-commercial use.
                        </p>
                        <p>
                            7.4. License to App
                        </p>
                        <p>
                            By creating, uploading, posting, sending, receiving, storing, or otherwise making available any User Content on or through the The Beyond Lifestyle App, you grant the Company a non-exclusive, worldwide, royalty-free,
                            irrevocable, perpetual (or for the term of the protection), sub-licensable and transferable license to such User Content to access, use, store, copy, modify, prepare derivative works of, distribute, publish, transmit,
                            stream, broadcast, and otherwise exploit in any manner such User Content to provide and/or promote the The Beyond Lifestyle App, in any media or App. Insofar as User Content includes personal information, such User Content
                            will only be used for these purposes if such use complies with applicable data protection laws in accordance with our Privacy Policy. Unless you provide specific consent, we do not claim any ownership rights in any User
                            Content and nothing in these Terms will be deemed to restrict any rights that you may have to use or exploit your User Content.
                        </p>
                        <p>
                            7.5. Content Ownership
                        </p>
                        <p>
                            You are solely responsible for all User Content that you make available on or through the App. Accordingly, you represent and warrant that: <br />
                            (i) you either are the sole and exclusive owner of all User Content that you make available on or through the App or you have all rights, licenses, consents and releases that are necessary to grant to us the rights in and to
                            such User Content, as contemplated under this Agreement; and <br />
                            (ii) neither the User Content nor your posting, uploading, publication, submission or transmittal of the User Content or our use of such User Content (or any portion thereof) as contemplated under these Terms will infringe,
                            misappropriate or violate a third party's Intellectual Property Rights, or rights of publicity or privacy, or result in the violation of any applicable law or regulation.
                        </p>
                        <p>
                            We respect copyright law and expects our Users to do the same. If you believe that any content on the The Beyond Lifestyle App infringes copyrights you own, please contact us at connect@tblsinfo.com .
                        </p>

                        <h4>8. TERMS SPECIFIC TO THE APP</h4>
                        <p>
                            8.1. Fees & Subscriptions
                        </p>
                        <p>
                            The Beyond Lifestyle may charge a subscription fee to Users in consideration for the use of certain aspects of the The Beyond Lifestyle App. The Beyond Lifestyle reserves the right to revisit and revise its subscription fees
                            and amounts at its sole discretion. The Services shall be available to users with different modes of subscription.The Beyond Lifestyle reserves its right to modify the different modes of subscription at its sole discretion.
                            The Beyond Lifestyle shall advise users of any changes to any modes of subscription thereon after.
                        </p>
                        <p>
                            The subscription period for the Services you purchase shall commence on the date on which the Services are made available to you (the “Subscription Term”).
                        </p>
                        <p>
                            Notwithstanding anything in these Terms of Service, upon a termination of this Agreement or a cancellation of any Service on the App, including any subscription, the Services shall only be cancelled at the end of the
                            duration for which the Service has been paid for. If the Services are terminated prior to the end of a duration that has been paid, the Services, including any The Beyond Lifestyle shall remain available until the end of the
                            duration paid for.
                        </p>
                        <p>
                            You will have the right to access and use the Services for the remaining period of the duration in which you cancel your Account. A user can still access the The Beyond Lifestyle for the duration of the remaining period Upon
                            the expiration of the Subscription Term or earlier cancellation of your Account as described in these Terms, your rights to access and use the Services shall terminate and your Account shall be deactivated, except to the
                            extent expressly provided otherwise in these Terms.
                        </p>
                        <p>
                            8.2. Penalties
                        </p>
                        <p>
                            A user is liable for no penalty post the cancellation of subscription.
                        </p>
                        <p>
                            If the application cancels a subscription of a user due to a suspicious activity, the user would get an email to showcase the reasons for the same.
                        </p>
                        <p>
                            8.3. The Beyond Lifestyle Marketplace
                        </p>
                        <p>
                            As an incentive to enable you to adopt better fitness habits, The Beyond Lifestyle may provide, from time to time, goods, benefits, and other incentives (the “Incentives”) that may include Third Party Products. You may
                            purchase these Incentives with The Beyond Lifestyle on the App.
                        </p>
                        <p>
                            You agree that The Beyond Lifestyle has no responsibility for any Incentives. You are contracting directly with the relevant third party when you redeem or use your Incentives to acquire Third Party Products and any
                            redemption or use happens on third party website or apps that are not controlled by us. You agree that we have no control over and do not guarantee the quality, safety or legality of any Third Party Products or Incentives,
                            the truth or accuracy of their offers, or the ability of third parties to provide any goods, services or other benefits offered. Save as otherwise expressly agreed with you, we and our affiliates do not act as agent,
                            contractor, partner or any form of representative of the third parties whose Third Party Products are promoted via the App. Our operation of the App should not be taken as an endorsement (express or implied) of any goods,
                            services or other benefits offered on it, nor of any third party.
                        </p>
                        <p>
                            The Beyond Lifestyle is entitled to alter the selection of Incentives, and the number of The Beyond Lifestyle required to redeem any such Incentives offered on the App, as well as to limit the number of possible redemptions
                            of any Incentives by any user in its absolute discretion.
                        </p>

                        <h4>9. INTELLECTUAL PROPERTY</h4>

                        <p>
                            9.1. Our Intellectual Property
                        </p>
                        <p>
                            The Beyond Lifestyle and our licensors retain all right, title, and interest in and to all Intellectual Property Rights related in and to the App. The The Beyond Lifestyle logos and names are intellectual property of
                            Digifish Media Private Limited. All other product names, company names, marks, logos, and symbols on the App or connected to App Services may be the trademarks of their respective owners. Nothing in the Terms of Service
                            grants you a right to use any The Beyond Lifestyle name. All images and text, and all page headers, custom graphics and button icons are service marks and/or trade dress of The Beyond Lifestyle. All Website and App design,
                            text, graphics, the selection and arrangement thereof, Copyright ©, Digifish3 Media Private Limited. ALL RIGHTS RESERVED.
                        </p>
                        <p>9.2. Your Intellectual Property</p>
                        <p>
                            1. Obligation and Indemnification
                        </p>
                        <p>
                            When you post User Content on the App or through the Services or provide The Beyond Lifestyle with User Content, you understand and acknowledge that you are solely responsible for such User Content. Further, you represent
                            and warrant that you have the right, power, and authority to <br />
                            (a) post that User Content without violating the rights of third parties, and <br />
                            (b) grant the licenses specified below.
                        </p>
                        <p>
                            You acknowledge and agree that the poster of User Content, and not The Beyond Lifestyle, is responsible for any User Content including any harms caused to you, another User, or a third party by such User Content.
                        </p>
                        <p>
                            You will indemnify, defend, and hold harmless The Beyond Lifestyle, our affiliates, and our respective directors, officers, employees, representatives, and agents (each an “Indemnified Party”) from any and all claims,
                            damages, liabilities, costs, losses, and expenses (including, but not limited to, reasonable attorneys’ fees and all related costs and expenses) arising from or relating to any claim, suit, proceeding, demand, or action
                            brought by you or a third party or other User against an Indemnified Party relating to or arising out of any User Content you post.
                        </p>
                        <p>
                            2. Your Rights & License to The Beyond Lifestyle and App Users
                        </p>
                        <p>
                            You retain all ownership rights in any User Content you post on The Beyond Lifestyle. To the extent permitted by applicable law, you also grant The Beyond Lifestyle and our successors and affiliates a royalty-free,
                            sub-licensable, transferable, perpetual, irrevocable, non-exclusive, worldwide license to use, reproduce, modify, publish, list information regarding, edit, translate, distribute, publicly perform, publicly display, and make
                            derivative works of all such User Content and your name, voice, and/or likeness as contained in your User Content, in whole or in part, and in any form, media, or technology, whether now known or hereafter developed, for use
                            in connection with the App and The Beyond Lifestyle’s, our successors’ and affiliates’ businesses, including, without limitation, for promoting and redistributing part or all of the App (and derivative works thereof) in any
                            media formats and through any media channels.
                        </p>
                        <p>
                            9.3. Third Party Intellectual Property
                        </p>
                        <p>
                            The App may contain links to third-party websites. The App may also contain applications that allow you to access third-party websites via our App. Such third-party websites or applications are owned and operated by the
                            third parties and/or their licensors. The inclusion of any link or application on the App does not imply that we endorse the linked site or application. You use the links and third-party websites at your own risk and agree
                            that your use of an application or third-party website accessed via the App is on an “as is” and “as available” basis without any warranty for any purpose.
                        </p>
                        <p>
                            9.4. Compliance with Intellectual Property Laws
                        </p>
                        <p>
                            When accessing the The Beyond Lifestyle App or obtaining Services on our App, you agree to obey the law and you agree to respect the intellectual property rights of others. Your use of the App and App Services is at all
                            times governed by and subject to laws regarding copyright, trademark and other intellectual property ownership. You agree not to upload, download, display, perform, transmit or otherwise distribute any information or content
                            in violation of any third party's copyrights, trademarks or other intellectual property or proprietary rights. You agree to abide by laws regarding copyright ownership and use of intellectual property, and you shall be
                            solely responsible for any violations of any relevant laws and for any infringements of third party rights caused by any content you provide or transmit or that is provided or transmitted using your Account.
                        </p>
                        <p>
                            The Beyond Lifestyle reserves the right to immediately remove any content, article or materials that have infringed on the rights of The Beyond Lifestyle or of a third party or that violate intellectual property rights
                            generally. Our policy is to remove such infringing content or materials and investigate such allegations immediately.
                        </p>
                        <p>
                            9.5. Infringement
                        </p>
                        <p></p>
                        <p>
                            If we find that any User has infringed the rights of the Company or of a third party, or otherwise violated any intellectual property laws, we may immediately suspend and/or terminate such User’s access to the App and any
                            Services. The Company will act promptly upon receipt of proper notification of claimed copyright infringement to remove or disable access to the allegedly infringing content. If you have evidence, know, or have a good faith
                            belief that your rights or the rights of a third party have been violated and you want the Company to delete, edit, or disable the material in question, please provide the Company with notice via email to
                            connect@tblsinfo.com .
                        </p>
                        <p>
                            If you believe that your User Content that was removed (or to which access was disabled) is not infringing, or that you have the authorization from the copyright owner, the copyright owner's agent, or pursuant to the law, to
                            post and use the material in your User Content, you may send a written notice containing the following information to the Company:
                        </p>
                        <p>(1) your physical or electronic signature;</p>
                        <p>(2) identification of the User Content that has been removed or to which access has been disabled and the location at which the Content appeared before it was removed or disabled;</p>
                        <p>(3) a statement that you have a good faith belief that the User Content was removed or disabled as a result of mistake or a misidentification of the User Content; and</p>
                        <p>
                            (4) your name, address, telephone number, and email address, a statement that you consent to the jurisdiction of India, and a statement that you will accept service from the person who provided notification of the alleged
                            infringement. If we receive such a notice, the Company may send a copy of the counter-notice to the original complaining party informing that person that it may replace the removed content or cease disabling it in 10
                            business days. Unless the copyright owner files an action seeking a court order against the content provider, member or user, the removed content may be replaced, or access to it restored, in 10 to 14 business days or more
                            after receipt of the counter-notice, at our sole discretion.
                        </p>

                        <h4>10. FEEDBACK</h4>
                        <p>
                            We welcome and encourage our users to provide feedback, comments, ideas and suggestions about the App, including, without limitation, how to make improvements to the our App and Services (“Feedback“). Any Feedback you submit
                            to us will be considered non-confidential and non-proprietary to you. You may submit Feedback by emailing us, through the Website, through the App or by other means of communication. By submitting any Feedback, you agree
                            that: <br />
                            (a) your disclosure is voluntary, gratuitous, unsolicited, and without restriction and will not place The Beyond Lifestyle under any fiduciary or other obligation, <br />
                            (b) your ideas do not contain the confidential or proprietary information of third parties. By submitting Feedback to us, you grant us a nonexclusive, worldwide, royalty-free, irrevocable, sub-licensable, perpetual license
                            to use and publish those ideas and materials for any purpose, without compensation to you. You further acknowledge and agree that, by acceptance of your submission, The Beyond Lifestyle does not waive any rights to use
                            similar or related ideas, including those known or developed by the Company or obtained from sources other than you.
                        </p>

                        <h4>11. TERM AND TERMINATION</h4>

                        <p>
                            11.1. Termination
                        </p>
                        <p>
                            Unless both you and The Beyond Lifestyle expressly agree otherwise in writing, either of us may terminate this Agreement in our sole discretion, at any time, without explanation, upon written notice to the other, which will
                            result in the termination of the other Terms of Service as well, except as otherwise provided herein. You may provide written notice to connect@tblsinfo.com . In the event you properly terminate this Agreement, your
                            right to use the App and Services will be revoked and your Account will be closed at the end of the month that you have already paid for. If you attempt to terminate this Agreement while having The Beyond Lifestyle
                            outstanding, you agree that you may lose all your outstanding The Beyond Lifestyle and that it is your obligation and responsibility to spend and apply any The Beyond Lifestyle toward any reward, offer, or object.
                        </p>
                        <p>
                            Without limiting our other rights or remedies, we may, but are not obligated to, temporarily or indefinitely revoke access to the App, deny your registration, or permanently revoke your access to the App and refuse to
                            provide any or all Services to you if: (i) you breach the letter or spirit of any terms and conditions of this Agreement or any other provisions of the Terms of Service; (ii) we suspect or become aware that you have provided
                            false or misleading information to us; or (iii) we believe, in our sole discretion, that your actions may cause legal liability for you, our Users, or The Beyond Lifestyle or our affiliates; may be contrary to the interests
                            of the App or the The Beyond Lifestyle community; or may involve illicit or illegal activity. If your Account is temporarily or permanently closed, you may not use the App under the same Account or a different Account or
                            reregister under a new Account without The Beyond Lifestyle’s prior written consent. If you attempt to use the App under a different Account, we reserve the right to reclaim available funds in that Account and/or use an
                            available payment method to pay for any amounts owed by you to the extent permitted by applicable law.
                        </p>
                        <p>
                            11.2. Account Data
                        </p>
                        <p>
                            Except as otherwise required by law, if your Account is closed for any reason, you will no longer have access to data, messages, files, or other material you keep on the App and that that any closure of your Account may
                            involve deletion of any content stored in your Account for which The Beyond Lifestyle will have no liability whatsoever. The Company, in its sole discretion and as permitted or required by law, may retain some or all of your
                            Account information.
                        </p>
                        <p>
                            11.3. Survival
                        </p>
                        <p>
                            After this Agreement terminates, the terms of this Agreement and the other Terms of Service that expressly or by their nature contemplate performance after this Agreement terminates or expires will survive and continue in
                            full force and effect. For example, the provisions protecting intellectual property, indemnification, payment of fees, reimbursement and setting forth limitations of liability each, by their nature, contemplate performance
                            or observance after this Agreement terminates. Without limiting any other provisions of the Terms of Service, the termination of this Agreement for any reason will not release you or The Beyond Lifestyle from any obligations
                            incurred prior to termination of this Agreement or that thereafter may accrue in respect of any act or omission prior to such termination.
                        </p>

                        <h4>12. APP STORE REQUIREMENTS</h4>
                        <p>
                            You acknowledge and agree that the availability of the App is dependent on the third party stores from which you download the application, including Apple Inc.’s (“Apple”) App Store (the “Apple App Store”) and Google LLC’s
                            (“Google”) App Store (“Google Play”).
                        </p>
                        <p>
                            As a user of the Apple App Store, we are required to include certain legal terms in our Terms of Service, and these are set out in Schedule 1 to these Terms. You agree to comply with, and your licence to use our application
                            is conditioned upon your compliance with, such Apple App Store terms and conditions. To the extent such other terms and conditions from such Apple App Store are less restrictive than, or otherwise conflict with, the terms
                            and conditions of these Terms of Service, the more restrictive or conflicting terms and conditions in these Terms of Service apply.
                        </p>
                        <p>
                            Through our App, you may purchase (“In-App Purchase”) certain goods or features designed to enhance the performance of the Service. When you make an In-App Purchase, you are doing so through the Apple iTunes service and you
                            are agreeing to their respective Terms and Conditions, available at https://www.apple.com/legal/internet-services/itunes/dev/stdeula/ or http://www.apple.com/legal/internet-services/itunes/us/terms.html. The Beyond Lifestyle
                            is not a party to any In-App Purchase.
                        </p>

                        <h4>13. GENERAL</h4>
                        <p>
                            13.1. Governing Law
                        </p>
                        <p>
                            These Terms will be interpreted in accordance with the laws of India applicable therein, without regard to conflict-of-law provisions. Any legal proceedings must be brought in India unless we both agree to some other
                            location. You and The Beyond Lifestyle both consent to venue and personal jurisdiction in India.
                        </p>
                        <p>
                            All arbitrations under Haryana jurisdiction only.
                        </p>
                        <p>
                            13.2. Severability
                        </p>
                        <p>
                            If any provision of this Agreement is held to be invalid or unenforceable, such provision will be struck and will not affect the validity and enforceability of the remaining provisions.
                        </p>
                        <p>
                            13.3. Non-Assignability
                        </p>
                        <p>
                            You may not assign, transfer or delegate this Agreement and your rights and obligations hereunder without our prior written consent.The Beyond Lifestyle may without restriction assign, transfer or delegate this Agreement and
                            any rights and obligations hereunder, at its sole discretion, with 30 days prior notice. Your right to terminate this Agreement at any time remains unaffected.
                        </p>
                        <p>
                            13.4. Waiver
                        </p>
                        <p>
                            Our failure to enforce any right or provision in these Terms will not constitute a waiver of such right or provision unless acknowledged and agreed to by us in writing. Except as expressly set forth in these Terms, the
                            exercise by either party of any of its remedies under these Terms will be without prejudice to its other remedies under these Terms or otherwise permitted under law.
                        </p>
                        <p>
                            13.5. Entire Agreement
                        </p>
                        <p>
                            Except as they may be supplemented by additional terms and conditions, policies, guidelines or standards, the Terms of Service constitute the entire Agreement between The Beyond Lifestyle and the User pertaining to the
                            subject matter hereof, and supersede any and all prior oral or written understandings or agreements between The Beyond Lifestyleand you in relation to the access to and use of the App.
                        </p>
                        <p>
                            13.6. Modification and Waiver
                        </p>
                        <p>
                            The Beyond Lifestyle reserves the right to modify these Terms at any time in accordance with this provision. If we make changes to these Terms, we will post the revised Terms on the App and update the “Last Updated” date at
                            the top of these Terms. We will also provide you with notice of the modifications by email at least 30 days before the date they become effective. If you disagree with the revised Terms, you may terminate this Agreement with
                            immediate effect. We will inform you about your right to terminate the Agreement in the notification email. If you do not terminate your Agreement before the date the revised Terms become effective, your continued access to
                            or use of the App will be deemed to constitute acceptance of such revised Terms.
                        </p>
                        <p>
                            14. ACKNOWLEDGEMENT
                        </p>
                        <p>
                            BY USING THE “THE BEYOND LIFESTYLE APP”, APP SERVICES OR ACCESSING THE WEBSITE SITE OR APPLICATIONS, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF USE AND AGREE TO BE BOUND BY THEM.
                        </p>
                        <p>
                            15. INQUIRIES
                        </p>
                        <p>
                            BY USING OR ACCESSING THE WEBSITE OR APP, USING ANY SERVICES, YOU ACKNOWLEDGE AND ACCEPT THAT SUBMITTING YOUR TELEPHONE NUMBER OR EMAIL TO US VIA THE WEBSITE OR APP CONSTITUTES AN INQUIRY TO THE BEYOND LIFESTYLE, AND THAT WE
                            MAY CONTACT YOU AT THE NUMBER OR EMAIL SUBMITTED EVEN IF SUCH NUMBER APPEARS IN ANY DO NOT CALL LISTS (TAKING INTO ACCOUNT INQUIRY EXCEPTION TIME FRAMES AS APPROPRIATE).
                        </p>
                        <p>
                            16. OUR DETAILS
                        </p>
                        <p>
                            This website and our Services are owned and operated by Digifish3 Media Private Limited, with our registered office at 032, 4th Floor, Emerald Plaza, Sector-65, Gurugram, Haryana-122018. India.
                        </p>
                        <p>
                            You can contact us:
                        </p>
                        <p>
                            • by post, to the postal address given above;
                        </p>
                        <p>
                            • by email, using connect@tblsinfo.com.
                        </p>

                        <h4>APPLE APP STORE TERMS</h4>
                        <p>
                            1. These Terms are concluded between you and The Beyond Lifestyle, and not with Apple. The Beyond Lifestyle and its contents are the responsibility of us, our licensors and users of The Beyond Lifestyle (to the extent that
                            such users create, submit or distribute any content via The Beyond Lifestyle) and not Apple.
                        </p>
                        <p>
                            2. The licence to use The Beyond Lifestyle granted under these Terms is a non-transferable license for you to useThe Beyond Lifestyle on an Apple-branded product that you own or control in accordance with the usage rules set
                            forth in the Apple App Store Terms of Services, except that The Beyond Lifestyle may be accessed, acquired, and used by other accounts associated with you via any family sharing or volume purchasing arrangements with Apple.
                        </p>
                        <p>
                            3. You and we acknowledge that Apple has no obligation whatsoever to furnish any maintenance and support services with respect to The Beyond Lifestyle. We do not offer maintenance or support services in connection with The
                            Beyond Lifestyle.
                        </p>
                        <p>
                            4. Apple will have no warranty obligation whatsoever with respect to The Beyond Lifestyle, and any claims, losses, liabilities, damages, costs or expenses attributable to any failure of The Beyond Lifestyle to conform to any
                            applicable warranty set out in these Terms will be solely our responsibility.
                        </p>
                        <p>
                            5. We, not Apple, are responsible for addressing any claims by you or any third party relating to The Beyond Lifestyle or your possession and/or use of The Beyond Lifestyle, including, but not limited to: (i) product
                            liability claims; (ii) any claim that The Beyond Lifestyle fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection or similar legislation.
                        </p>
                        <p>
                            6. In the event of any third-party claim that The Beyond Lifestyle or your possession or use of The Beyond Lifestyle infringes that third party’s intellectual property rights, The Beyond Lifestyle and not Apple will be
                            responsible for the investigation, defence, settlement and discharge of any such intellectual property infringement claim.
                        </p>
                        <p>
                            7. You represent and warrant that (i) you are not located in a country that is subject to India embargo, or that has been designated by the India Government as a “terrorist supporting” country; and (ii) you are not listed on
                            any India Government list of prohibited or restricted parties.
                        </p>
                        <p>
                            8. You must comply with any third-party terms that are applicable to the use of The Beyond Lifestyle from time to time.
                        </p>
                        <p>
                            9. Apple and Apple’s subsidiaries are third party beneficiaries of these Terms, and upon your acceptance of these Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce these Terms
                            against you as a third-party beneficiary thereof.
                        </p>
                    </div>
                </div>
            </section>

        </>
    )
}
