import { ReactLenis, useLenis } from '@studio-freight/react-lenis';
import Router from './pages/route/Router';

function App() {
  return (
    <ReactLenis
      root
      options={{ gestureOrientataion: "both" }}
    >
      <div className="App">
        <Router />
      </div>
    </ReactLenis>
  );
}

export default App;
