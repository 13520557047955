import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from '../root/Home';
import Header from '../../component/Header';
import Footer from '../../component/Footer';
import Contact from '../root/Contact';
import Support from '../root/Support';
import TermsAndConditions from '../root/TermsAndConditions';
import PrivacyPolicy from '../root/PrivacyPolicy';
import BlogList from '../blog/BlogList';
import BlogDetail from '../blog/BlogDetail';
import Pricing from '../root/Pricing';
import Refundsandcancellationpolicy from '../root/Refundsandcancellationpolicy';
import Download from '../download/index'
import PageNoFund from '../root/PageNoFund';

export default function Router() {
    return (
        <BrowserRouter>
            <Header />
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='/support' element={<Support />} />
                <Route path='/pricing' element={<Pricing />} />
                <Route path='/blog' element={<BlogList />} />
                <Route path='/blog/:title' element={<BlogDetail />} />
                <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route path='/download' element={<Download />} />
                <Route path='/refunds-and-cancellation-policy' element={<Refundsandcancellationpolicy />} />
                <Route path='/*' element={<PageNoFund />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    )
}
