import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { get } from '../../helpers/api_helper';
import { Helmet } from 'react-helmet';

export default function BlogDetail() {

    const navigate = useNavigate()
    const params = useParams();
    const [data, setData] = useState(undefined);

    useEffect(() => {

        if (params?.title)
            getBlog();
        
    }, [params]);

    const getBlog = () => {
        get("blog/title?title=" + params?.title)
            .then(res => {
                if (res?.statusCode == 200) {
                    setData(res?.data);
                    window.scrollTo(0, 0);
                }
                if (res?.statusCode == 404) {
                    navigate('/*')
                }
            })
            .catch(err => {
                console.log("error while getting blog details", err);
            })
    }


    return (
        <>
            {data ?
                <Helmet>
                    <title>{data?.seoTitle}</title>
                    <link rel="canonical" href={data?.ogurl} />
                    <meta name="description" content={data?.metaDesc} />
                    <meta name="keywords" content={data?.metaKey} />
                    <meta property="og:type" content={data?.ogType} />
                    <meta property="og:title" content={data?.seoTitle} />
                    <meta property="og:description" content={data?.metaDesc} />
                    <meta property="og:url" content={data?.ogurl} />
                    <meta property="og:image" content={data?.ogImage} />

                </Helmet>
                : null}
            <section className="p-l-r banner-section inner-banner">
                <div className="inner-banner-content">
                    <h1 className="heading1 text_gradient">
                        Blogs
                    </h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="star"><i className="fa-solid fa-star-of-life"></i></li>
                            <li className="breadcrumb-item"><Link to="/blog">Blogs</Link></li>
                        </ol>
                    </nav>
                </div>
            </section>
            <section className="blog-section-list top-50px bottom p-l-r">
                <svg className="separator separator1 separator--up" width="100%" height="100%" viewBox="0 0 100 10" preserveAspectRatio="none">
                    <path className="separator__path path-anim" data-path-to="M 0 0 C 35 21 70 0 100 0 L 0 0 Z" vector-effect="non-scaling-stroke" d="M 0 0 C 37 0 70 0 100 0 L 0 0 Z" />
                </svg>
                <div className="container-fluid">
                    <div className="row mt_100px">
                        <div className={`col-lg-${data?.images?.length ? '8' : '12'} order_2`}>
                            <div className="blog-left-content">
                                {data?.cat?.length ?
                                    <p><strong>{data?.cat[0]?.title}</strong></p>
                                    : null}
                                <h3 className="tags">
                                    {data?.allTags?.map((item, index) => (
                                        item?.name + (data?.allTags?.length - 1 === index ? "" : " | ")
                                    ))}
                                </h3>
                                <h2 className="heading1 text_gradient">{data?.title}</h2>
                                <div dangerouslySetInnerHTML={{ __html: data?.desc }} />
                            </div>
                        </div>
                        {data?.images?.length ?
                            <div className="col-lg-4 order_1">
                                <div className="blog-right-section">
                                    <img src={data?.images[0]} alt={data?.title} />
                                </div>
                            </div>
                            : null}
                    </div>
                </div>
            </section>

            <section className="four-section bottom">
                <div className="container-fluid">
                    <div className="row mb-5">
                        <div className="col-lg-12">
                            <div className="mental-health-content">
                                <h3 className="heading1 text_gradient">
                                    Related Blogs
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {data?.relatedBlogs?.map((item) => (
                            <div className="col-lg-4">
                                <div className="card-section">
                                    <Link to={`/blog/${item?.url}`}>
                                        <div className="picture-thumb">
                                            <img src={item?.thumb} alt={item?.title} />
                                        </div>
                                        <div className="card-detail">
                                            <p>{item?.title}</p>
                                            {item?.cat?.length ?
                                                <h5>{item?.cat[0]?.title}</h5>
                                                : null}
                                            <h3>
                                                {item?.allTags?.map((item2, index) => (
                                                    item2?.name + (item?.allTags?.length - 1 === index ? "" : " | ")
                                                ))}
                                            </h3>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </section>
        </>
    )
}
