import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
export default function Support() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <>
            <section class="p-l-r banner-section inner-banner">

                <div class="inner-banner-content">
                    <h1 class="heading1">
                        Support
                    </h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link href="/">Home</Link></li>
                            <li class="star"><i class="fa-solid fa-star-of-life"></i></li>
                            <li class="breadcrumb-item active">Support</li>
                        </ol>
                    </nav>

                </div>


            </section>



            <section class="contact-page top-50px bottom p-l-r faq-section" style={{ backgroundColor: 'transparent' }}>
                <svg class="separator separator1 separator--up" width="100%" height="100%" viewBox="0 0 100 10" preserveAspectRatio="none">
                    <path class="separator__path path-anim" data-path-to="M 0 0 C 35 21 70 0 100 0 L 0 0 Z" vector-effect="non-scaling-stroke" d="M 0 0 C 37 0 70 0 100 0 L 0 0 Z" />
                </svg>
                <div class="contact-sec ">
                    <div class="container">
                        <div class="row mt_100px">
                            <div class="col-lg-6">
                                <div class="contact-detail">
                                    <h3 class="sub-heading" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>For Support & Questions?
                                    </h3>
                                    <h2 class="heading" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>Ask away!</h2>
                                    <div class="call-to-action">

                                        <a href="mailto:support@beyondlifestyle.com" class="" style={{ visibility: 'visible', animationName: 'fadeInUp' }}><i class="fa-solid fa-envelope"></i>support@beyondlifestyle.com</a>
                                    </div>
                                    {/* <div class="social_media">
                                        <p>Follow us:</p>
                                        <ul>
                                            <li><a href="https://www.facebook.com/TheBeyondLifestyleApp" target="_blank"><i class="fa-brands fa-facebook-f"></i></a></li>

                                            <li><a href="https://twitter.com/LifestyleApp24" target="_blank"><i class="fa-brands fa-x-twitter"></i></a></li>

                                            <li><a href="https://www.instagram.com/the_beyond_lifestyle/" target="_blank"><i class="fa-brands fa-instagram"></i></a></li>

                                            <li><a href="https://www.youtube.com/@TheBeyondLifestyle" target="_blank"><i class="fa-brands fa-youtube"></i></a></li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="footer-fomr-section">
                                    <div class="contact-form-footer contact-form">

                                        <div class="right-section">



                                            <svg class="svg svg-circle" viewBox="0 0 380 380">



                                                <path d="M405.5,323Q334,396,231,428.5Q128,461,97,355.5Q66,250,119.5,183.5Q173,117,266.5,88Q360,59,418.5,154.5Q477,250,405.5,323Z" fill="#d8c1bb">

                                                    <animate attributeName="d" dur="10s" repeatCount="indefinite" values="M405.5,323Q334,396,231,428.5Q128,461,97,355.5Q66,250,119.5,183.5Q173,117,266.5,88Q360,59,418.5,154.5Q477,250,405.5,323Z;

      M400.5,317.5Q328,385,231,418Q134,451,110,350.5Q86,250,116,159.5Q146,69,241,85Q336,101,404.5,175.5Q473,250,400.5,317.5Z;

      M428.5,355Q371,460,252.5,455.5Q134,451,100,350.5Q66,250,106,160Q146,70,241.5,85Q337,100,411.5,175Q486,250,428.5,355Z;

M377.5,327.5Q340,405,244,416Q148,427,115,338.5Q82,250,114.5,161Q147,72,255,63.5Q363,55,389,152.5Q415,250,377.5,327.5Z;                                                                                          M406,333Q346,416,240.5,432.5Q135,449,73.5,349.5Q12,250,87.5,174.5Q163,99,268.5,67Q374,35,420,142.5Q466,250,406,333Z;

      M405.5,323Q334,396,231,428.5Q128,461,97,355.5Q66,250,119.5,183.5Q173,117,266.5,88Q360,59,418.5,154.5Q477,250,405.5,323Z;"></animate>

                                                    <animateTransform attributeName="transform" type="rotate" dur="20s" repeatCount="indefinite" from="0,250,250" to="360,250,250"></animateTransform>

                                                </path>

                                            </svg>

                                            <div class="bird_img">

                                                <img src={require('../../assets/images/mail.png')} alt="" />

                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
