import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { get } from '../../helpers/api_helper';
import { Helmet } from "react-helmet";

export default function BlogList() {

    const [data, setData] = useState([]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        get("blog/list")
            .then(res => {
                if (res?.statusCode == 200) {
                    setData(res?.data);
                }
            })
            .catch(err => {
                console.log("error blog list", err);
            })
    }

    return (
        <>
            {data ?
                <Helmet>
                    <title>{data?.metaTitle}</title>
                    <meta name="description" content={data?.metaDesc} />
                </Helmet>
                : null}
            <section class="p-l-r banner-section inner-banner">
                <div class="inner-banner-content">
                    <h1 class="heading1">
                        Blogs
                    </h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li class="star"><i class="fa-solid fa-star-of-life"></i></li>
                            <li class="breadcrumb-item active">Blogs</li>
                        </ol>
                    </nav>
                </div>
            </section>
            <section class="blog-section-list top bottom p-l-r">
                <svg class="separator separator1 separator--up" width="100%" height="100%" viewBox="0 0 100 10"
                    preserveAspectRatio="none">
                    <path class="separator__path path-anim" data-path-to="M 0 0 C 35 21 70 0 100 0 L 0 0 Z"
                        vector-effect="non-scaling-stroke" d="M 0 0 C 37 0 70 0 100 0 L 0 0 Z" />
                </svg>
                <div class="container-fluid">
                    <div class="row">
                        {data?.map((item) => (
                            <div class="col-lg-4">
                                <div class="card-section">
                                    <Link to={`/blog/${item?.url}`}>
                                        <div class="picture-thumb">
                                            <img src={item?.thumb}
                                                alt={item?.title} />
                                        </div>
                                        <div class="card-detail">
                                            <p>{item?.title}</p>
                                            {item?.cat?.length ?
                                                <h5>{item?.cat[0]?.title}</h5>
                                                : null}
                                            <h3 className="tags">
                                                {item?.allTags?.map((item2, index) => (
                                                    item2?.name + (item?.allTags?.length - 1 === index ? "" : " | ")
                                                ))}
                                            </h3>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        ))}
                        {/* <div class="col-lg-4">
                            <div class="card-section">
                                <a href="are-you-looking-to-take-your-mental-toughness-to-the-next-level.html">
                                    <div class="picture-thumb">
                                        <img src={require('../../assets/images/blog/mental-wellness/Are-you-looking-to-take-your-mental-toughness-to-the-next-level.jpg')}
                                            alt="mental-wellness" />
                                    </div>
                                    <div class="card-detail">
                                        <p>Are you looking to take your mental toughness to the next level?
                                        </p>
                                        <h5>Mental Wellness</h5>
                                        <h3>selfcare | yoga | positive living</h3>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="card-section">

                                <a href="why-are-healthy-sleep-habits-your-superpower-sleep-tight-and-live-bright.html">

                                    <div class="picture-thumb">

                                        <img src={require('../../assets/images/blog/sleep/Why-are-healthy-sleep-habits-your-superpower-Sleep-tight-and-live-bright.jpg')}
                                            alt="why-are-healthy-sleep-habits-your-superpower-sleep-tight-and-live-bright" />



                                    </div>

                                    <div class="card-detail">

                                        <p>Why are healthy sleep habits your superpower? Sleep tight and live bright!
                                        </p>
                                        <h5>Sleep</h5>


                                        <h3>holistic living | sleep sounds | journey to wellbeing</h3>

                                    </div>

                                </a>

                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="card-section">

                                <a href="how-kids-can-celebrat-joy-and-navigate-sadness.html">

                                    <div class="picture-thumb">

                                        <img src={require('../../assets/images/blog/kids-wellness/How-kids-can-celebrate-joy-and-navigate-sadness.jpg')}
                                            alt="How-kids-can-celebrate-joy-and-navigate-sadness" />



                                    </div>

                                    <div class="card-detail">

                                        <p>How can kids celebrate joy and navigate sadness?
                                        </p>
                                        <h5>Kids Wellness</h5>


                                        <h3>meditation for kids | wellness solutions | emotional balance</h3>

                                    </div>

                                </a>

                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="card-section">

                                <a href="spice-up-your-life-simple-steps-to-embrace-ayurvedic-living.html">

                                    <div class="picture-thumb">

                                        <img src={require('../../assets/images/blog/ayurveda/spice-up-your-life-simple-steps-to-embrace-ayurvedic-living.jpg')}
                                            alt="Spice up your Life!" />



                                    </div>

                                    <div class="card-detail">

                                        <p>Spice up your life! Simple steps to embrace ayurvedic living
                                        </p>
                                        <h5>Ayurveda</h5>


                                        <h3>ayurveda practices | vedic sciences | ayurveda herbs</h3>

                                    </div>

                                </a>

                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}
