import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
 
export default function Download() {
 
    useEffect(() => {
        function getMobileOperatingSystem() {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            if (/android/i.test(userAgent)) {
                return "Android";
            }
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return "iOS";
            }
            return "Unknown";
        }
 
        // Redirect based on the detected OS
        const os = getMobileOperatingSystem();
        if (os === "Android") {
            window.location.href = "https://thebeyondlifestyle.com/app/";
        } else if (os === "iOS") {
            window.location.href = "https://apps.apple.com/in/app/the-beyond-lifestyle/id6478144849";
        } else {
            // Redirect to a generic page if the OS is unknown
            window.location.href = "https://thebeyondlifestyle.com/app/";
        }
    }, []);
 
    return (
        <div>
            <section class="banner-section">
                <div class="banner-content">
 
                </div>
            </section>
        </div>
    )
}