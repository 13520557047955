import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function Refundsandcancellationpolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <section class="p-l-r banner-section inner-banner">
                <div class="inner-banner-content">
                    <h1 class="heading1 text_gradient">
                        Refunds/ Cancellation Policy
                    </h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link href="/">Home</Link></li>
                            <li class="star"><i class="fa-solid fa-star-of-life"></i></li>
                            <li class="breadcrumb-item active">Refunds/ Cancellation Policy</li>
                        </ol>
                    </nav>
                </div>
            </section>
            <section class="privacy-policy-section top bottom p-l-r-2">
                <svg class="separator separator1 separator--up" width="100%" height="100%" viewBox="0 0 100 10"
                    preserveAspectRatio="none">
                    <path class="separator__path path-anim" data-path-to="M 0 0 C 35 21 70 0 100 0 L 0 0 Z"
                        vector-effect="non-scaling-stroke" d="M 0 0 C 37 0 70 0 100 0 L 0 0 Z" />
                </svg>
                <div class="container-fluid">
                    <div class="row ">
                        <p>The application is based on a subscription model.Once a person subscribes the app, the subscription
                            can be cancelled before the next billing date of the subscription only.</p>
                        <h3>Shipping and Delivery Policy</h3>
                        <p>As it is an Online portal, the subscription charges of the application are charged online itself.</p>
                        <p>The Content Delivery is on the platform itself and caters to no physical delivery mechanism.</p>
                        <h3>International Pricing: </h3>
                        <p>• $ 49 – 3 Months</p>
                        <p>• $ 149 - Annual</p>
                        <h3>India Pricing:</h3>
                        <p>• INR 799 - 3 Months</p>
                        <p>• INR 1999 - Annual</p>
                    </div>
                </div>
            </section>
        </>
    )
}
