
import React, { useEffect, useState } from 'react'


export default function Pricing(props) {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <>
            <section className='p-l-r top bottom pricing_section'>
                <div className='container-fluid'>
                    <div className='w-full login-section p-l-r row' >
                        <div className='col-lg-6'>
                            <div className='left-section h-full'>
                                <h4 className='text-[36px] max-[1080px]:text-[32px] max-[576px]:text-[24px]  max-[375px]:text-[20px] font-EuclidFlex-Light -text--text-color leading-tight tracking-wide'>Unlock a world of exclusive<br /> features and content with<br />
                                    <strong className='login_heading'>The BeyondLifestyle PLUS <img src={require('../../assets/images/plus1.png')} /></strong></h4>
                                <h3 className='py-4 font-EuclidFlex-Bold text-[30px] max-[576px]:text-[22px] max-[375px]:text-[18px] -text--text-color1'>What will you get?</h3>
                                <ul className='flex flex-col gap-3'>
                                    <li className='flex items-start'><span><i class="fa-solid fa-headphones"></i></span><p className='text-[15px] ml-2 font-Poppins-Light'><strong>Premium Content Access:</strong><br /> Access a vast library of premium guided sessions and masterclasses.</p></li>
                                    <li className='flex items-start'><span><i class="fa-solid fa-palette"></i></span><p className='text-[15px] ml-2 font-Poppins-Light'><strong>Exclusive Themes and Customization:</strong><br />Personalize your app with exclusive themes.</p>
                                    </li>
                                    <li className='flex items-start'><span><i class="fa-solid fa-headset"></i></span><p className='text-[15px] ml-2 font-Poppins-Light'><strong>Priority Support:</strong><br />Experience faster response times and dedicated support.</p> </li>
                                </ul>
                            </div>
                        </div>
                        {/* login screen */}
                        <div className='col-lg-6'>
                            <div className='payment_screen'>
                                <div className='left_img_section'>
                                    <img src={require('../../assets/images/login.png')} />
                                </div>

                                <div className='right_content_section payment_screen_child' >
                                    <h4 className='font-GothamBook font-bold text-[26px] max-[1250px]:text-[25px] max-[576px]:text-[20px] leading-8'>International Pricing</h4>
                                    <ul className='py-3'>
                                        <li className='text-[16px] my-2 font-QuattrocentoSans-Regular'><strong className='font-QuattrocentoSans-bold '>Silver -</strong> $ 49 – 3 Months</li>
                                        <li className='text-[16px] my-2 font-QuattrocentoSans-Regular'><strong className='font-QuattrocentoSans-bold '>Platinum -</strong> $ 149 - Annual</li>
                                    </ul>
                                    <h4 className='font-GothamBook font-bold text-[26px] max-[1250px]:text-[25px] max-[576px]:text-[20px] leading-8'>India Pricing</h4>
                                    <ul className='py-3'>
                                        <li className='text-[16px] my-2 font-QuattrocentoSans-Regular'><strong className='font-QuattrocentoSans-bold '>Silver -</strong> INR 799 - 3 Months</li>
                                        <li className='text-[16px] my-2 font-QuattrocentoSans-Regular'><strong className='font-QuattrocentoSans-bold '>Platinum -</strong> INR 1999 - Annual</li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div >
                </div>
            </section>
        </>
    )
}
