import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function PageNoFund() {

    const navigate = useNavigate()

    useEffect(() => {
        setTimeout(() => {
            navigate('/')
        }, 1000)
    }, [])

    return (
        <div className='page_not_fund'>
            <h4>404</h4>
        </div>
    )
}
