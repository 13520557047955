import React, { useEffect, useRef, useState } from 'react'
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { get } from "../../helpers/api_helper";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';
import { Helmet } from 'react-helmet';

export default function Home() {

    const loaction = useLocation();
    const navigate = useNavigate()

    const gsapTrackRef = useRef(null);
    const [activeTab, setActiveTab] = useState("defaultCity");
    const [blog, setBlog] = useState([]);
    const horizontalSectionRef = useRef(null);
    const [open, setOpen] = useState('0');

    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    const openCity = (evt, cityName) => {
        setActiveTab(cityName);
    };
    // console.log('loaction', loaction)

    useEffect(() => {
        window.scrollTo(0, 0);
        document.getElementById("defaultOpen").click();
        getData();
    }, []);

    useGSAP(() => {
        gsap.registerPlugin(ScrollTrigger);
        const section_1 = document.getElementById("vertical");
        const col_left = document.querySelector(".col_left");
        const timeln = gsap.timeline({
            paused: true
        });
        let mm = gsap.matchMedia();
        mm.add({
            isMobile: "(max-width:768px)",
            isDesktop: "(min-width:1024px)"
        }, (context) => {
            let {
                isMobile,
                isDesktop
            } = context.conditions;
            gsap.from('.about__img', {

                scrollTrigger: {

                    trigger: '.second-section',

                    start: 'top bottom',

                    scrub: 1.9

                },

                yPercent: isMobile ? 20 : 80,

            })


            gsap.from('.second-section .left-section div', {
                y: isMobile ? 20 : (i, el) => (1 - parseFloat(el.getAttribute('data-speed'))),
                scrollTrigger: {
                    trigger: '.second-section',
                    start: 'top bottom',
                    scrub: 1.9
                }
            })

            // gsap.from('.about__img img', {

            //     scrollTrigger: {

            //         trigger: '.about',

            //         start: 'top bottom',

            //         scrub: 1.9

            //     },

            //     scale: isMobile ? 1.1 : 1.3

            // })

            gsap.to('.about__txt', {

                scrollTrigger: {

                    trigger: '.about__wrapp',

                    start: 'top bottom',

                    scrub: 1.9

                },

                yPercent: isMobile ? 60 : 50

            })


            gsap.from('.work__item,.work__item h4,.work__item p', {

                y: (i, el) => (1 - parseFloat(el.getAttribute('data-speed'))),

                scrollTrigger: {

                    trigger: '.main_box',

                    start: 'top bottom',

                    scrub: 1.1

                }

            })


            gsap.from('.img-bo img', {

                scale: isMobile ? 1.2 : 1.4,

                scrollTrigger: {

                    trigger: '.main_box',

                    start: 'top bottom',

                    scrub: 1.9

                }

            })
            timeln.fromTo(col_left, {
                y: 0
            }, {
                y: isMobile ? '0' : '60vh',
                duration: 1,
                ease: 'none'
            }, 0);
            const scroll_1 = ScrollTrigger.create({
                animation: timeln,
                trigger: section_1,
                start: 'top top',
                end: 'bottom center',
                scrub: true
            });



        })
    });

    const getData = () => {
        get("screen/home")
            .then(res => {
                if (res?.statusCode == 200) {
                    setBlog(res?.data);
                }
            })
            .catch(err => {
                console.log("err while getting home data", err);
            })
    }

    return (
        <>
            <Helmet>
                <title>The BeyondLifeStyle - Holistic Well-being App</title>
                <meta name="description"
                    content="Discover a world of holistic well-being with 'The BeyondLifeStyle' app. Access exclusive content, blogs, podcasts, yoga, ayurveda practices, and more for comprehensive wellness support." />
                <meta name="keywords"
                    content="Holistic well-being, BeyondLifeStyle, Mindfulness, Yoga, Ayurveda, Sleeping tips, Astro-vedic sciences, Podcasts, Live sessions, Wellness app, Meditation, Self-care, Health and wellness, Lifestyle app, Personal growth" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="The BeyondLifeStyle - Holistic Well-being App" />
                <meta property="og:description"
                    content="Discover a world of holistic well-being with 'The BeyondLifeStyle' app. Access exclusive content, blogs, podcasts, yoga, ayurveda practices, and more for comprehensive wellness support." />
                <meta property="og:url" content="https://thebeyondlifestyle.com/" />
                <meta property="og:image" content={"https://thebeyondlifestyle.com/static/media/banner-img.e75a2ac78233b2c99550.png"} />
                {/* <meta property="og:location" content={data?.ogLocation} /> */}
            </Helmet>
            <section class="p-l-r banner-section">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-7">
                            <div class="banner-content">
                                <h2 class="mb-20px text_gradient">Mindfulness made simple!
                                </h2>
                                <h1>Embrace wellness journey <br /> anytime
                                    anywhere. <span>Find your inner
                                        peace.</span>
                                </h1>
                                <div class="download-box">

                                    <a href="https://apps.apple.com/in/app/the-beyond-lifestyle/id6478144849" target="_blank">
                                        <img src={require('../../assets/images/apple.png')} alt="iOS App Download" />
                                    </a>
                                    <a href="https://play.google.com/store/apps/details?id=com.tblstyle" target="_blank">
                                        <img src={require('../../assets/images/android.png')} alt="iOS App Download" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="main_right">
                                <div class="banner-right-section">
                                    <div class="circle">
                                        <img src={require('../../assets/images/banner-img.png')} alt="tbls-banner-img" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="second-section top bottom p-l-r" style={{ overflow: 'hidden' }}>
                <svg class="separator separator1 separator--up" width="100%" height="100%" viewBox="0 0 100 10"
                    preserveAspectRatio="none">
                    <path class="separator__path path-anim" data-path-to="M 0 0 C 35 21 70 0 100 0 L 0 0 Z"
                        vector-effect="non-scaling-stroke" d="M 0 0 C 37 0 70 0 100 0 L 0 0 Z" />
                </svg>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-5 col-md-4">
                            <div class="left_scroll_img">
                                <div class="left-section about__img">
                                    <div data-speed="40">
                                        <img src={require('../../assets/images/img.png')} alt="about" />
                                    </div>
                                    <div data-speed="-40" class="div1">
                                        <img src={require('../../assets/images/img-1.png')} alt="about" />
                                    </div>
                                    <div data-speed="30">
                                        <img src={require('../../assets/images/img-2.png')} alt="about" />
                                    </div>
                                    <div data-speed="-90">
                                        <img src={require('../../assets/images/img-3.png')} alt="about" />
                                    </div>
                                    <div data-speed="100">
                                        <img src={require('../../assets/images/img-4.png')} alt="about" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-8">
                            <div class="right-section right_section_a">
                                <h3 class="heading">
                                    Embark on a <br />
                                    transformative journey with <br />
                                    <span class="text_gradient">
                                        The Beyond Lifestyle
                                        <img src={require('../../assets/images/line.png')} alt="line" />
                                    </span>
                                </h3>
                                <div class="price">
                                    <p><strong>Accessible 24/7. In multiple languages.</strong></p>
                                    <p class="mr">Join us and experience improved well-being and self-care. <br /> Your very own
                                        mindful
                                        cosmos…
                                    </p>
                                </div>
                                <div class="detail-about">
                                    <p class="mb-0">
                                        With a simple sign-up, explore the world of holistic well-being.
                                    </p>
                                    <p class="">
                                        Gain access to unlimited exclusive content, blogs, harmonies, sleeping tips, astro-vedic
                                        sciences, podcasts, live sessions, yoga, ayurveda practices, and much more.
                                    </p>
                                    <p class="mb-0 para"><strong>Quarterly and annual subscription packs available.</strong></p>
                                </div>
                                <a href="https://thebeyondlifestyle.com/app">Try for free</a>
                                <p class="limitedaccess">*Limited access</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="thee-section section_top p-l-r">
                <svg class="separator separator--up" width="100%" height="100%" viewBox="0 0 100 10" preserveAspectRatio="none">
                    <path class="separator__path path-anim" data-path-to="M 0 0 C 35 21 70 0 100 0 L 0 0 Z"
                        vector-effect="non-scaling-stroke" d="M 0 0 C 37 0 70 0 100 0 L 0 0 Z" />
                </svg>
                <svg class="separator separator--down" width="100%" height="100%" viewBox="0 0 100 10" preserveAspectRatio="none">
                    <path class="separator__path path-anim" data-path-to="M 0 0 C 29 6 78 17 100 0 L 100 10 H 0 Z"
                        vector-effect="non-scaling-stroke" d="M 0 0 C 18 1 61 9 100 0 L 100 10 H 0 Z" />
                </svg>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="left-section main_box">
                                <ul class="list-box">
                                    <li class="one-box work__item" data-speed="-200">
                                        <div class="img-bo">
                                            <img src={require('../../assets/images/img1.png')} alt="img3" />
                                            <svg class="svg svg-circle" viewBox="0 0 500 500">
                                                <path
                                                    d="M405.5,323Q334,396,231,428.5Q128,461,97,355.5Q66,250,119.5,183.5Q173,117,266.5,88Q360,59,418.5,154.5Q477,250,405.5,323Z"
                                                    fill="#d8c1bb">
                                                    <animate attributeName="d" dur="10s" repeatCount="indefinite"
                                                        values="M405.5,323Q334,396,231,428.5Q128,461,97,355.5Q66,250,119.5,183.5Q173,117,266.5,88Q360,59,418.5,154.5Q477,250,405.5,323Z;
                                       M400.5,317.5Q328,385,231,418Q134,451,110,350.5Q86,250,116,159.5Q146,69,241,85Q336,101,404.5,175.5Q473,250,400.5,317.5Z;
                                       M428.5,355Q371,460,252.5,455.5Q134,451,100,350.5Q66,250,106,160Q146,70,241.5,85Q337,100,411.5,175Q486,250,428.5,355Z;
                                       M377.5,327.5Q340,405,244,416Q148,427,115,338.5Q82,250,114.5,161Q147,72,255,63.5Q363,55,389,152.5Q415,250,377.5,327.5Z;                                                                                          M406,333Q346,416,240.5,432.5Q135,449,73.5,349.5Q12,250,87.5,174.5Q163,99,268.5,67Q374,35,420,142.5Q466,250,406,333Z;
                                       M405.5,323Q334,396,231,428.5Q128,461,97,355.5Q66,250,119.5,183.5Q173,117,266.5,88Q360,59,418.5,154.5Q477,250,405.5,323Z;">
                                                    </animate>
                                                    <animateTransform attributeName="transform" type="rotate" dur="20s"
                                                        repeatCount="indefinite" from="0,250,250" to="360,250,250">
                                                    </animateTransform>
                                                </path>
                                            </svg>
                                        </div>
                                        <div class="right-content-l">
                                            <h4 class="text_gradient">Prioritize your well-being</h4>
                                            <p>Stressed & burnt out? Find your zen with lifestyle hacks for wellbeing. Explore
                                                life-changing habits now. Start your journey towards a more balanced and fulfilling
                                                life
                                                today. List your rituals daily!
                                            </p>
                                        </div>
                                    </li>
                                    <li class="two-box work__item" data-speed="-400">
                                        <div class="img-bo">
                                            <img src={require('../../assets/images/img2.png')} alt="img3" />
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 288 288">
                                                <linearGradient id="PSgrad_0" x1="70.711%" x2="0%" y1="70.711%" y2="0%">
                                                    <stop offset="0%" stop-color="#f5d6ba" stop-opacity="1" />
                                                    <stop offset="100%" stop-color="#f5d6ba" stop-opacity="1" />
                                                </linearGradient>
                                                <path fill="url(#PSgrad_0)">
                                                    <animate repeatCount="indefinite" attributeName="d" dur="10s"
                                                        values="M37.5,186c-12.1-10.5-11.8-32.3-7.2-46.7c4.8-15,13.1-17.8,30.1-36.7C91,68.8,83.5,56.7,103.4,45 c22.2-13.1,51.1-9.5,69.6-1.6c18.1,7.8,15.7,15.3,43.3,33.2c28.8,18.8,37.2,14.3,46.7,27.9c15.6,22.3,6.4,53.3,4.4,60.2 c-3.3,11.2-7.1,23.9-18.5,32c-16.3,11.5-29.5,0.7-48.6,11c-16.2,8.7-12.6,19.7-28.2,33.2c-22.7,19.7-63.8,25.7-79.9,9.7 c-15.2-15.1,0.3-41.7-16.6-54.9C63,186,49.7,196.7,37.5,186z; M51,171.3c-6.1-17.7-15.3-17.2-20.7-32c-8-21.9,0.7-54.6,20.7-67.1c19.5-12.3,32.8,5.5,67.7-3.4C145.2,62,145,49.9,173,43.4 c12-2.8,41.4-9.6,60.2,6.6c19,16.4,16.7,47.5,16,57.7c-1.7,22.8-10.3,25.5-9.4,46.4c1,22.5,11.2,25.8,9.1,42.6 c-2.2,17.6-16.3,37.5-33.5,40.8c-22,4.1-29.4-22.4-54.9-22.6c-31-0.2-40.8,39-68.3,35.7c-17.3-2-32.2-19.8-37.3-34.8 C48.9,198.6,57.8,191,51,171.3z; M37.5,186c-12.1-10.5-11.8-32.3-7.2-46.7c4.8-15,13.1-17.8,30.1-36.7C91,68.8,83.5,56.7,103.4,45 c22.2-13.1,51.1-9.5,69.6-1.6c18.1,7.8,15.7,15.3,43.3,33.2c28.8,18.8,37.2,14.3,46.7,27.9c15.6,22.3,6.4,53.3,4.4,60.2 c-3.3,11.2-7.1,23.9-18.5,32c-16.3,11.5-29.5,0.7-48.6,11c-16.2,8.7-12.6,19.7-28.2,33.2c-22.7,19.7-63.8,25.7-79.9,9.7 c-15.2-15.1,0.3-41.7-16.6-54.9C63,186,49.7,196.7,37.5,186z    " />
                                                </path>
                                            </svg>
                                        </div>
                                        <div class="right-content-l">
                                            <h4 class="text_gradient">Know yourself better
                                            </h4>
                                            <p>Unleash your hidden strengths with insightful psychometric tests. Gain clarity through
                                                podcasts from leading experts in psychology and well-being. Connect with supportive
                                                communities fueled by resilience. Share your journey with like-minded individuals now.
                                            </p>
                                        </div>
                                    </li>
                                    <li class="three-box work__item" data-speed="-600">
                                        <div class="img-bo">
                                            <img src={require('../../assets/images/img3.png')} alt="img3" />
                                            <svg class="blob" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
                                                <g transform="translate(300,300)">
                                                    <path
                                                        d="M120,-157.6C152.7,-141.5,174.3,-102.6,194.8,-58.8C215.3,-14.9,234.6,33.8,228.4,80.8C222.2,127.8,190.4,173.1,148.1,184C105.8,195,52.9,171.5,-2.4,174.8C-57.8,178.2,-115.6,208.4,-137.5,190.9C-159.3,173.3,-145.3,108,-153,56.3C-160.7,4.6,-190.2,-33.4,-178.3,-54.2C-166.4,-75.1,-113.2,-78.8,-76.6,-93.6C-40,-108.3,-20,-134.2,11.9,-150.5C43.7,-166.8,87.4,-173.6,120,-157.6Z"
                                                        fill="#ddb27b" />
                                                </g>
                                            </svg>
                                        </div>
                                        <div class="right-content-l">
                                            <h4 class="text_gradient">Explore 100+ harmonies & meditations</h4>
                                            <p>Access our entire library of stress and anxiety relief, with sleep sounds, and
                                                breathing exercises. Discover your ideal bedtime routine with hours of soothing tunes. All are designed to help you achieve the perfect night's rest. De-stress your mind anytime, all at your fingertips.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 pr_0">
                            <div class="right_side">
                                <div class="right-section">
                                    <img src={require('../../assets/images/shap4.png')} alt="shap4" />
                                    <div class="second-child">
                                        <img src={require('../../assets/images/threeface.png')} alt="image" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="four-section top">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="mental-health-content">
                                <h3 class="heading1 text_gradient">
                                    Holistic wellness tools for real life
                                    <img src={require('../../assets/images/line.png')} alt="line" />
                                </h3>
                                <p>Discover healthy practices that reduce stress and build resilience, for a happier, healthier
                                    you.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="space_box">
                    </div>
                    <div class="circle-group">
                        <ul>
                            <li>
                                <button class="tablinks"
                                    className={`tablinks ${activeTab === "City1" ? "active" : ""}`}
                                    onClick={(e) => openCity(e, "City1")}
                                    id="defaultOpen"
                                >Mindful Living</button>
                            </li>
                            <li>
                                <button class="tablinks"
                                    className={`tablinks ${activeTab === "City2" ? "active" : ""}`}
                                    onClick={(e) => openCity(e, "City2")}
                                >Astro-Life</button>
                            </li>
                            <li>
                                <button class="tablinks"
                                    className={`tablinks ${activeTab === "City3" ? "active" : ""}`}
                                    onClick={(e) => openCity(e, "City3")}>Kids Wellness</button>
                            </li>
                        </ul>
                    </div>
                    <div id="work" class="tabcontent active mental-health-content-details" style={{ border: '5px solid #d8c1bb', display: activeTab === "City1" ? "block" : "none" }}>
                        <div class="row ">
                            <div class="col-lg-6">
                                <div class="left-section">
                                    <p>Ready to unlock the secrets of your inner well-being? Discover yourself, and escape the
                                        stress
                                        cycle. Experience mindfulness while clearing mental clutter. Try our user-friendly app to
                                        feel
                                        good with ayurvedic natural practices. With every note, you'll find solace and heightened
                                        awareness.
                                    </p>
                                    <p>Move mindfully towards success today!</p>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="right-section">
                                    <img src={require('../../assets/images/px_mindful_living.jpg')} class="man_img" alt="mindful_living" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="family" class="tabcontent mental-health-content-details" style={{ border: '5px solid #f5d6ba', display: activeTab === "City2" ? "block" : "none" }}>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="left-section">
                                    <p>Planetary positions influence human behavior and our well-being. Some of us find astrological
                                        interpretations calming and stress-reducing. Gaining insights in personality, creating
                                        self-awareness can be empowering and guide individuals towards personal growth and
                                        well-being.
                                    </p>
                                    <p>So, take the plunge and start your journey today!</p>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="right-section">
                                    <img src={require('../../assets/images/px_astro_life.jpg')} class="man_img" alt="astro_life" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="self" class="tabcontent mental-health-content-details" style={{ border: '5px solid #858f86', display: activeTab === "City3" ? "block" : "none" }}>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="left-section">
                                    <p>Secure a bright future together, plant the seeds of emotional acceptance, and watch kids'
                                        minds
                                        blossom. Learn expert tips for building empathy through meditation, guided exercises for a
                                        more
                                        connected and well-rounded family environment.
                                    </p>
                                    <p>Explore and experience the difference!</p>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="right-section">
                                    <img src={require('../../assets/images/px_kids_wellness.jpg')} class="man_img" alt="kids_wellness" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="about_section  p-l-r" >
                <svg class="separator separator_11 separator--down" width="100%" height="100%" viewBox="0 0 100 10"
                    preserveAspectRatio="none">
                    <path class="separator__path path-anim" data-path-to="M 0 0 C 29 6 78 17 100 0 L 100 10 H 0 Z"
                        vector-effect="non-scaling-stroke" d="M 0 0 C 18 1 61 9 100 0 L 100 10 H 0 Z" />
                </svg>
                <div class="container-fluid" id="about">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="left-section">
                                <div class="fixed_img">
                                    <img src={require('../../assets/images/blog/about-1.png')} alt="about" />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="right-section">
                                <h4 class="heading1 text_gradient">
                                    About Us

                                </h4>
                                <p class="paragraph">In today's fast-paced cities where everyone could use a little help to lead a
                                    happy and healthy life! The Beyond Lifestyle app is here to lend a hand in empowering you to
                                    take
                                    control of your overall well-being and live your best life with daily meditation routines.
                                </p>
                                <p class="paragraph">Our app provides a safe and friendly space that encourages personal growth and
                                    helps you detoxify your soul step-by-step, promoting mindful living for a calmer and more
                                    focused
                                    life. We want you to feel at ease with soothing soundscapes, sleep time, and mental wellness
                                    features.
                                </p>
                                <p class="paragraph_b">Together, we help you overcome emotional pains and work towards living a
                                    happier and more fulfilling life. Start embracing a soulful life with a smile on your face!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="faq-section top bottom p-l-r" id="vertical">
                <div class="container-fluid" id="faq">
                    <h4 class="heading1 text_gradient">
                        Frequently Asked Questions
                        <img src={require('../../assets/images/line1.png')} alt="line" />
                    </h4>
                    <div class="row">
                        <div class="col-lg-7">
                            <div class="faq-content">
                                <Accordion open={open} toggle={toggle}>
                                    <AccordionItem>
                                        <AccordionHeader targetId="1">How do I create The Beyond Lifestyle account?</AccordionHeader>
                                        <AccordionBody accordionId="1">
                                            <ul>
                                                <li>Download the app from app store</li>
                                                <li>Select language</li>
                                                <li>Click on "Sign Up," follow on-screen instructions</li>
                                                <li>Sign in with an email id, provide a valid email address</li>
                                                <li>Set a password for registration</li>
                                            </ul>
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="2">Can I use multiple devices with one account?</AccordionHeader>
                                        <AccordionBody accordionId="2">
                                            <ul>
                                                <li>Yes, you can use your app account on multiple devices</li>
                                                <li>Your account is designed to sync seamlessly across various platforms</li>
                                                <li>It provides a consistent well-being experience </li>
                                            </ul>
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="3">How do I reset my password?</AccordionHeader>
                                        <AccordionBody accordionId="3">
                                            <ul>
                                                <li>Go to the login page, click on "Forgot Password" </li>
                                                <li>Follow the instructions sent to your registered email address</li>
                                            </ul>
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="4"> Is my personal information secure with The Beyond Lifestyle?</AccordionHeader>
                                        <AccordionBody accordionId="4">
                                            Absolutely. Beyond Lifestyle prioritizes the security and privacy of your personal
                                            information. We adhere to strict data protection measures to ensure your data is
                                            handled
                                            with the utmost care. For more details, refer to our Privacy Policy
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="5">How can I update my account information?</AccordionHeader>
                                        <AccordionBody accordionId="5">
                                            <ul>
                                                <li>You can update your account information, including email address and profile
                                                    details, within The Beyond Lifestyle app
                                                </li>
                                                <li>Navigate to the profile or settings section to make any necessary changes</li>
                                            </ul>
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="6">What does a The Beyond Lifestyle subscription include?</AccordionHeader>
                                        <AccordionBody accordionId="6">
                                            <ul>
                                                <li>The Beyond Lifestyle subscription provides access to a wide range of well-being
                                                    content
                                                </li>
                                                <li>It includes meditations, mindfulness exercises, harmonies for mental wellness
                                                </li>
                                                <li>Live Sessions with experts, and more. Explore a holistic approach to well-being
                                                </li>
                                            </ul>
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="7">How much does The Beyond Lifestyle subscription cost?</AccordionHeader>
                                        <AccordionBody accordionId="7">
                                            <ul>
                                                <li>Download the app from app store</li>
                                                <li>Visit the "Subscription" section within the app or our website to view the
                                                    current
                                                    pricing details and available plans
                                                </li>
                                                <li>Subscription pricing may vary based on the plan you choose</li>
                                            </ul>
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="8">Can I cancel my subscription anytime?</AccordionHeader>
                                        <AccordionBody accordionId="8">
                                            <ul>
                                                <li>Yes, you can cancel your The Beyond Lifestyle subscription at any time</li>
                                                <li>Navigate to the subscription settings within the app</li>
                                                <li>Follow the instructions to manage your subscription</li>
                                                <li>Note that cancellation details may vary based on the platform of subscription
                                                </li>
                                            </ul>
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="9">Is there a free trial available?</AccordionHeader>
                                        <AccordionBody accordionId="9">
                                            <ul>
                                                <li>Yes, The Beyond Lifestyle offers a 2 day free trial period for new users. </li>
                                                <li>Explore the app's access to limited features and content to ensure it aligns
                                                    with
                                                    your well-being goals before committing to a subscription
                                                </li>
                                            </ul>
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="10">How do I upgrade my subscription plan?</AccordionHeader>
                                        <AccordionBody accordionId="10">
                                            <p>To upgrade your subscription plan:
                                            </p>
                                            <ul>
                                                <li>Visit the subscription section in the app or on our website</li>
                                                <li>Follow the prompts to select the desired plan </li>
                                                <li>Complete the upgrade process</li>
                                            </ul>
                                        </AccordionBody>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-lg-5 ">
                            <div class="right-section col_left">
                                <svg class="svg svg-circle" viewBox="0 0 380 380">
                                    <path
                                        d="M405.5,323Q334,396,231,428.5Q128,461,97,355.5Q66,250,119.5,183.5Q173,117,266.5,88Q360,59,418.5,154.5Q477,250,405.5,323Z"
                                        fill="#d8c1bb">
                                        <animate attributeName="d" dur="10s" repeatCount="indefinite"
                                            values="M405.5,323Q334,396,231,428.5Q128,461,97,355.5Q66,250,119.5,183.5Q173,117,266.5,88Q360,59,418.5,154.5Q477,250,405.5,323Z;
                              M400.5,317.5Q328,385,231,418Q134,451,110,350.5Q86,250,116,159.5Q146,69,241,85Q336,101,404.5,175.5Q473,250,400.5,317.5Z;
                              M428.5,355Q371,460,252.5,455.5Q134,451,100,350.5Q66,250,106,160Q146,70,241.5,85Q337,100,411.5,175Q486,250,428.5,355Z;
                              M377.5,327.5Q340,405,244,416Q148,427,115,338.5Q82,250,114.5,161Q147,72,255,63.5Q363,55,389,152.5Q415,250,377.5,327.5Z;                                                                                          M406,333Q346,416,240.5,432.5Q135,449,73.5,349.5Q12,250,87.5,174.5Q163,99,268.5,67Q374,35,420,142.5Q466,250,406,333Z;
                              M405.5,323Q334,396,231,428.5Q128,461,97,355.5Q66,250,119.5,183.5Q173,117,266.5,88Q360,59,418.5,154.5Q477,250,405.5,323Z;"></animate>
                                        <animateTransform attributeName="transform" type="rotate" dur="20s" repeatCount="indefinite"
                                            from="0,250,250" to="360,250,250"></animateTransform>
                                    </path>
                                </svg>
                                <div class="bird_img">
                                    <img src={require('../../assets/images/bird.png')} alt="bird" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="six-section bottom top p-l-r" id="horizontal">
                <div class="container-fluid">
                    <div class="header-title">
                        <h3 class="heading1 text_gradient ">
                            Blogs
                        </h3>
                        <div class="all_blog_btn">
                            <Link to="/blog">See all blogs</Link>
                        </div>
                    </div>
                    <div class="horizontal__content" ref={horizontalSectionRef}>

                        <Swiper
                            slidesPerView={1}
                            spaceBetween={10}
                            pagination={{
                                clickable: true,
                            }}
                            breakpoints={{
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 10,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 10,
                                },
                            }}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            loop={true}
                            modules={[Pagination, Autoplay]}
                            className="mySwiper"
                        >

                            {blog?.map((item) => (
                                <SwiperSlide key={item?._id}>
                                    <div class="card-section horizontal__item">
                                        <Link to={`/blog/${item?.url}`}>
                                            <div class="picture-thumb">
                                                <img src={item?.thumb}
                                                    alt={item?.title} />
                                            </div>
                                            <div class="card-detail">
                                                <p>{item?.title}</p>
                                                {item?.cat?.length ?
                                                    <h5>{item?.cat[0]?.title}</h5>
                                                    : null}
                                                <h3 className="tags">
                                                    {item?.allTags?.map((item2, index) => (
                                                        item2?.name + (item?.allTags?.length - 1 === index ? "" : " | ")
                                                    ))}
                                                </h3>
                                            </div>
                                        </Link>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        {/* <div class="card-section horizontal__item">
                            <a href="looking-for-ways-to-recharge-and-revitalize-yourself.html">
                                <div class="picture-thumb">
                                    <img src={require('../../assets/images/blog/mindfulness/Looking-for-ways-to-recharge-and-revitalize-yourself.jpg')}
                                        alt="Looking-for-ways-to-recharge-and-revitalize-yourself" />
                                </div>
                                <div class="card-detail">
                                    <p>Looking for ways to recharge and revitalize yourself?
                                    </p>
                                    <h5>Mindfulness</h5>
                                    <h3>meditation | mental wellness | relaxation </h3>
                                </div>
                            </a>
                        </div> */}
                        {/* <div class="card-section horizontal__item">
                            <a href="looking-for-ways-to-recharge-and-revitalize-yourself.html">
                                <div class="picture-thumb">
                                    <img src={require('../../assets/images/blog/mindfulness/Looking-for-ways-to-recharge-and-revitalize-yourself.jpg')}
                                        alt="Looking-for-ways-to-recharge-and-revitalize-yourself" />
                                </div>
                                <div class="card-detail">
                                    <p>Looking for ways to recharge and revitalize yourself?
                                    </p>
                                    <h5>Mindfulness</h5>
                                    <h3>meditation | mental wellness | relaxation </h3>
                                </div>
                            </a>
                        </div>
                        <div class="card-section horizontal__item">
                            <a href="are-you-looking-to-take-your-mental-toughness-to-the-next-level.html">
                                <div class="picture-thumb">
                                    <img
                                        src={require('../../assets/images/blog/mental-wellness/Are-you-looking-to-take-your-mental-toughness-to-the-next-level.jpg')}
                                        alt="Are- you-looking-to-take-your-mental -toughness -to -the -next -level" />

                                </div>
                                <div class="card-detail">
                                    <p>Are you looking to take your mental toughness to the next level?
                                    </p>
                                    <h5>Mental Wellness</h5>
                                    <h3>selfcare | yoga | positive living</h3>
                                </div>
                            </a>
                        </div>
                        <div class="card-section horizontal__item">
                            <a href="how-kids-can-celebrat-joy-and-navigate-sadness.html">
                                <div class="picture-thumb">
                                    <img src={require('../../assets/images/blog/kids-wellness/How-kids-can-celebrate-joy-and-navigate-sadness.jpg')}
                                        alt="kids-wellness/How-kids-can-celebrate-joy-and-navigate-sadness" />

                                </div>
                                <div class="card-detail">
                                    <p>How can kids celebrate joy and navigate sadness?
                                    </p>
                                    <h5>Kids Wellness</h5>
                                    <h3>meditation for kids | wellness solutions | emotional balance</h3>
                                </div>
                            </a>
                        </div>
                        <div class="card-section horizontal__item">
                            <a href="spice-up-your-life-simple-steps-to-embrace-ayurvedic-living.html">
                                <div class="picture-thumb">
                                    <img
                                        src={require('../../assets/images/blog/ayurveda/spice-up-your-life-simple-steps-to-embrace-ayurvedic-living.jpg')}
                                        alt="spice-up-your-life-simple-steps-to-embrace-ayurvedic-living" />

                                </div>
                                <div class="card-detail">
                                    <p>Spice up your life! Simple steps to embrace ayurvedic living
                                    </p>
                                    <h5>Ayurveda</h5>
                                    <h3>ayurveda practices | vedic sciences | ayurveda herbs</h3>
                                </div>
                            </a>
                        </div> */}
                    </div>
                </div>
            </section>
            <section class="six-section bottom top p-l-r mobile_view d-none">
                <div class="container-fluid">
                    <div class="header-title">
                        <h3 class="heading1 text_gradient ">
                            Blogs
                        </h3>
                        <div class="all_blog_btn">
                            <Link to="/blog">See all blogs</Link>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="col-lg-4">
                            <div class="card-section">
                                <a href="looking-for-ways-to-recharge-and-revitalize-yourself.html">
                                    <div class="picture-thumb">
                                        <img
                                            src={require('../../assets/images/blog/mindfulness/Looking-for-ways-to-recharge-and-revitalize-yourself.jpg')}
                                            alt="Looking-for-ways-to-recharge-and-revitalize-yourself" />

                                    </div>
                                    <div class="card-detail">
                                        <p>Looking for ways to recharge and revitalize yourself?
                                        </p>
                                        <h5>Mindfulness</h5>
                                        <h3>meditation | mental wellness | relaxation</h3>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="card-section">
                                <a href="are-you-looking-to-take-your-mental-toughness-to-the-next-level.html">
                                    <div class="picture-thumb">
                                        <img
                                            src={require('../../assets/images/blog/mental-wellness/Are-you-looking-to-take-your-mental-toughness-to-the-next-level.jpg')}
                                            alt="Are-you-looking-to-take-your-mental-toughness-to-the-next-level" />

                                    </div>
                                    <div class="card-detail">
                                        <p>Are you looking to take your mental toughness to the next level?
                                        </p>
                                        <h5>Mental Wellness</h5>
                                        <h3>selfcare | yoga | positive living</h3>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="card-section">
                                <a href="why-are-healthy-sleep-habits-your-superpower-sleep-tight-and-live-bright.html">
                                    <div class="picture-thumb">
                                        <img
                                            src={require('../../assets/images/blog/sleep/Why-are-healthy-sleep-habits-your-superpower-Sleep-tight-and-live-bright.jpg')}
                                            alt="Why-are-healthy-sleep-habits-your-superpower-Sleep-tight,-and-live-bright!" />

                                    </div>
                                    <div class="card-detail">
                                        <p>Why are healthy sleep habits your superpower? Sleep tight and live bright!
                                        </p>
                                        <h5>Sleep</h5>
                                        <h3>holistic living | sleep sounds | journey to wellbeing</h3>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="card-section ">
                                <a href="how-kids-can-celebrat-joy-and-navigate-sadness.html">
                                    <div class="picture-thumb">
                                        <img src={require('../../assets/images/blog/kids-wellness/How-kids-can-celebrate-joy-and-navigate-sadness.jpg')}
                                            alt="How-kids-can-celebrate-joy-and-navigate-sadness" />
                                    </div>
                                    <div class="card-detail">
                                        <p>How can kids celebrate joy and navigate sadness?
                                        </p>
                                        <h5>Kids Wellness</h5>
                                        <h3>meditation for kids | wellness solutions | emotional balance</h3>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="card-section">
                                <a href="spice-up-your-life-simple-steps-to-embrace-ayurvedic-living.html">
                                    <div class="picture-thumb">
                                        <img
                                            src={require('../../assets/images/blog/ayurveda/spice-up-your-life-simple-steps-to-embrace-ayurvedic-living.jpg')}
                                            alt="spice-up-your-life-simple-steps-to-embrace-ayurvedic-living" />

                                    </div>
                                    <div class="card-detail">
                                        <p>Spice up your life! Simple steps to embrace ayurvedic living
                                        </p>
                                        <h5>Ayurveda</h5>
                                        <h3>ayurveda practices | vedic sciences | ayurveda herbs</h3>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
