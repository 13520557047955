import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <footer class="bottom top p-l-r">
            <div class="container-fluid">
                <ul class="footer-menu">
                    <li><a href="https://thebeyondlifestyle.com/app" target="_blank">Login</a></li>
                    <li><a href="/#about">About</a></li>
                    <li><a href="/#faq">FAQ's</a></li>
                    <li><Link to="/pricing">Pricing</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                    <li><Link to="/support">Support</Link></li>
                    <li><Link to="/blog">Blogs</Link></li>
                    <li><Link to="/download" target='_blank'>Download</Link></li>
                    <li><Link to="/terms-and-conditions">Terms</Link></li>
                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                    <li><Link to="/refunds-and-cancellation-policy">Refunds / Cancellation Policy</Link></li>
                </ul>
                <div class="download-box">
                    <a href="https://apps.apple.com/in/app/the-beyond-lifestyle/id6478144849" target="_blank">
                        <img src={require('../assets/images/apple.png')} alt="iOS App Download" />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.tblstyle" target="_blank">
                        <img src={require('../assets/images/android.png')} alt="iOS App Download" />
                    </a>
                </div>
                <div class="social-media">
                    <ul>
                        <li><a href="https://www.facebook.com/TheBeyondLifestyleApp" target="_blank"><i
                            class="fa-brands fa-facebook-f"></i></a></li>
                        <li><a href="https://twitter.com/LifestyleApp24" target="_blank"><i
                            class="fa-brands fa-x-twitter"></i></a></li>
                        <li><a href="https://www.instagram.com/the_beyond_lifestyle/" target="_blank"><i
                            class="fa-brands fa-instagram"></i></a></li>
                        <li><a href="https://www.youtube.com/@TheBeyondLifestyle" target="_blank"><i
                            class="fa-brands fa-youtube"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/the-beyond-lifestyle/" target="_blank"><i
                            class="fa-brands fa-linkedin-in"></i></a></li>
                    </ul>
                </div>
                <div class="copy-right">
                    <p>Copyright © <span id="date"></span> The Beyondlifestyle. All rights reserved.</p>
                </div>
            </div>
        </footer>
    )
}
